import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to import axios
import { Helmet } from "react-helmet-async";
import TopNavigationBar from "../../../components/TopNavigationBar";
import Image from "../../../images/heroimages/ManagerialDesks.webp";
import HeroSection from "./HeroSection";
import Footer from "../../../components/Footer";
import ProductItem from "../../../components/ProductItem";
import LoadingSpinner from "../../../components/LoadingSpinner";
import FloatingButtons from "../../../components/FloatingButtons";

export default function OfficeManagerialDesk() {
    const [officeConferenceTable, setofficeConferenceTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImageURL = "Image Not Loaded"; // Replace with your actual placeholder image URL
    useEffect(() => {
        const fetchConferenceTables = async () => {
            setIsLoading(true);
            try {
                // Assuming HP's brand_id is "2" (replace "2" with the actual brand_id of HP)
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products?category_id=1&subcategory_id=8&type_id=16`
                );
                const processedOfficeWorkstation = response.data.map(
                    (officeConferenceTableItem) => {
                        const mainImageURL =
                            officeConferenceTableItem.images &&
                            officeConferenceTableItem.images.length > 0
                                ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${officeConferenceTableItem.images[0].url}`
                                : placeholderImageURL;
                        return { ...officeConferenceTableItem, mainImageURL };
                    }
                );

                setofficeConferenceTable(processedOfficeWorkstation);
                console.log(processedOfficeWorkstation);
            } catch (error) {
                console.error("Failed to fetch office workstations:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchConferenceTables();
    }, []);

    return (
        <div>
            <Helmet>
                <title>
                    Office Desks | Functional & Stylish Workspace Solutions |
                    Newline Limited
                </title>
                <meta
                    name="description"
                    content="Explore Newline Limited's office desks, tailored for functionality and style. From executive desks to compact solutions, find the perfect desk to enhance your workspace aesthetics and productivity."
                />
                <meta
                    name="keywords"
                    content="office desks, executive desks, compact office solutions, stylish workspace, Newline Limited, office furniture Kenya, ergonomic desks, modern office design"
                />
                <link
                    rel="canonical"
                    href="https://www.newline.co.ke/office-furniture/desks/office-desks"
                />
                <meta
                    property="og:title"
                    content="Office Workstation | Functional & Stylish Workspace Solutions | Newline Limited"
                />
                <meta
                    property="og:description"
                    content="Discover the perfect blend of functionality and style with Newline Limited's range of office desks. Elevate your workspace today."
                />
                <meta
                    property="og:url"
                    content="https://www.newline.co.ke/office-furniture/desks/office-desks"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="URL_TO_FEATURED_OFFICE_DESK_IMAGE"
                />
                <meta
                    property="newline:facebook"
                    content="https://www.facebook.com/newlineke"
                />
                <meta
                    property="newline:twitter"
                    content="https://x.com/ltd_newline"
                />
                <meta
                    property="newline:instagram"
                    content="https://www.instagram.com/newline_ltd"
                />
                <meta
                    property="newline:linkedin"
                    content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
                />
                <meta
                    property="newline:tiktok"
                    content="https://www.tiktok.com/@newlinelimited"
                />
            </Helmet>
            <TopNavigationBar />
            <HeroSection
                title="Managerial-Executive Desks: The Heart of a Productive and Stylish Workspace"
                description="In the quest for the perfect office setup, the choice of desk isn't just about functionality; it's a statement of style and leadership. Managerial-executive desks are at the intersection of sophistication, durability, and ergonomic design, offering a workspace that enhances decision-making, creativity, and comfort. Understanding the critical role these desks play, we offer a curated selection designed to meet the varied demands of modern leaders. This guide aims to assist you in finding an executive desk that complements your work habits, technological needs, and aesthetic preferences, ensuring your office is not just a place of work, but a realm of inspiration and efficiency."
                imageURL={Image}
                imageHeight="w-4/5"
            />
            <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 m-16 sm:m-4 sm:gap-x-0 sm:place-items-center">
                {isLoading ? (
                    <div>
                        <LoadingSpinner />
                    </div>
                ) : (
                    officeConferenceTable.map((product) => (
                        <div key={product.id} className="p-4 sm:p-2">
                            <ProductItem
                                title={product.title}
                                mainImageUrl={product.mainImageURL} // Changed from mainImageUrl to mainImageUrl
                            />
                        </div>
                    ))
                )}
            </div>

            <FloatingButtons/>
            <Footer backgroundColor="bg-white" />
        </div>
    );
}
