import React from "react";
import Footer from "../../../components/Footer";
import ProductItem from "../../../components/ProductItem";
import HeroSection from "./HeroSection";
import TopNavigationBar from "../../../components/TopNavigationBar";
import productData from "../../../data/office/chairs/VisitorChair.json";
import Image from "../../../images/homepage/cleo_visitors.webp";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function VisitorsChair() {
  return (
    <div className="bg-gray-100">
      <TopNavigationBar />
      <Helmet>
        <title>
          Visitor's Chairs | Comfortable & Stylish Seating Solutions | Newline
          Limited
        </title>
        <meta
          name="description"
          content="Discover visitor chairs from Newline Limited that offer both style and comfort. Our selection is designed to make guests feel valued and relaxed in any office setting."
        />
        <meta
          name="keywords"
          content="visitor chairs, office seating, comfortable chairs, stylish office furniture, Newline Limited, office furniture Kenya, durable visitor seating"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/chairs/visitor's-chairs"
        />
        <meta
          property="og:title"
          content="Visitor's Chairs | Comfortable & Stylish Seating Solutions | Newline Limited"
        />
        <meta
          property="og:description"
          content="Explore our range of visitor chairs designed for style, comfort, and durability. Ensure your guests feel welcome in your office space with Newline Limited."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/chairs/visitor's-chairs"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="URL_TO_FEATURED_VISITOR_CHAIR_IMAGE"
        />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <HeroSection
        title="Visitor's Chairs"
        description="Seeking visitor chairs that blend style with comfort? Our selection focuses on providing welcoming, comfortable seating for guests, ensuring they feel valued and relaxed in your space. Let us assist you in finding visitor chairs that complement your office aesthetics while meeting the highest standards of comfort and durability."
        imageURL={Image}
        imageHeight="w-3/5"
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 mx-16 my-0 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}
