import React, { useEffect, useState } from "react";
import axios from "axios"; // Importing axios for API calls
import ProductItem from "./ProductItem"; // Importing the ProductItem component for displaying each product

const ProductsList = ({ isLoading, setIsLoading }) => {
    const [displayedProducts, setDisplayedProducts] = useState([]); // State for storing the products to be displayed
    const placeholderImageURL = "Image Not Loaded"; // Replace with your actual placeholder image URL

    useEffect(() => {
        const fetchAPIProducts = async () => {
            setIsLoading(true);
            try {
                // Fetch products from the API
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products`
                );

                // Process the products, adding mainImageURL to each
                const processedAPIProducts = response.data.map((product) => {
                    const mainImageURL =
                        product.images && product.images.length > 0
                            ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${product.images[0].url}`
                            : placeholderImageURL;
                    return { ...product, mainImageURL };
                });

                shuffleAndDisplayProducts(processedAPIProducts);
            } catch (error) {
                console.error("Failed to fetch API products:", error);
            } finally {
                setIsLoading(false);
            }
        };

        const shuffleAndDisplayProducts = (apiProducts) => {
            // Shuffle the product array
            let shuffledProducts = shuffleArray(apiProducts);
            // Select the first 12 products to display
            setDisplayedProducts(shuffledProducts.slice(0, 12));
        };

        fetchAPIProducts();
    }, [setIsLoading]);

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    return (
        <div className="relative grid grid-cols-4 my-16 sm:my-8 gap-8 sm:grid-cols-1 place-items-center sm:gap-3">
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                displayedProducts.map((product) => (
                    <ProductItem {...product} key={product.id} mainImageUrl={product.mainImageURL} />
                ))
            )}
        </div> 
    );
}; 

export default ProductsList;
