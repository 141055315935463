import React from "react";
import TopNavigationBar from "../../../components/TopNavigationBar";
import productData from "../../../data/office/chairs/ReceptionChairs.json";
import HeroSection from "./HeroSection";
import ProductItem from "../../../components/ProductItem";
import Footer from "../../../components/Footer";
import Image from "../../../images/homepage/winsteel_3seater_chair.webp";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function ReceptionChairs() {
  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>
          Reception Chairs | Comfortable & Stylish Seating | Newline Limited
        </title>
        <meta
          name="description"
          content="Elevate your reception area with Newline Limited’s stylish and comfortable reception chairs. Tailored to create a welcoming environment for every guest."
        />
        <meta
          name="keywords"
          content="reception chairs, comfortable seating, office furniture, Newline Limited, stylish reception area, durable office chairs"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/chairs/reception-chairs"
        />
        <meta
          property="og:title"
          content="Reception Chairs | Comfortable & Stylish Seating | Newline Limited"
        />
        <meta
          property="og:description"
          content="Discover our collection of reception chairs designed to offer both aesthetics and comfort, making a lasting first impression."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/chairs/reception-chairs"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="URL_TO_FEATURED_RECEPTION_CHAIR_IMAGE"
        />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigationBar />
      <HeroSection
        title="Reception Chairs"
        description="In need of reception chairs that combine aesthetics with comfort for a welcoming environment? Our collection is tailored to enhance your reception area, ensuring every guest feels appreciated and at ease. We're here to help you select reception chairs that not only fit your space's design but also uphold comfort and durability standards, making first impressions last."
        imageURL={Image}
        imageHeight="w-full"
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 m-16 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}
