import React from "react";
import Couch from "../../images/about/couch.png";

export default function HeroSection() {
  return (
    <div>
      <div className="bg-[#3b5d50] h-[85vh] sm:h-[70vh]  w-full flex items-center justify-between px-16 sm:px-4 sm:flex sm:flex-col-reverse text-white">
        <div className="w-1/2 sm:w-full">
          <h1 className="font-bold text-3xl sm:text-lg">Contact Us</h1>
          <p className="text-sm my-4">
            Reach out to us with your ideas, questions, or feedback. Our
            dedicated team is here to provide you with personalized support,
            ensuring a seamless experience from your first inquiry to the final
            touches of your project.
          </p>
          <div className="flex gap-4 my-6">
            <button className="p-4 rounded-full sm:w-full w-1/4 bg-yellow-600 text-black font-bold">
              Shop Now
            </button>
            <button className="p-4 rounded-full sm:w-full w-1/4 bg-transparent border border-white font-bold">
              Explore
            </button>
          </div>
        </div>
        <div className="flex justify-end items-end">
          <img src={Couch} alt={Couch} />
        </div>
      </div>
    </div>
  );
}
