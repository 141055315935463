import React, { useState, useEffect } from "react";
import ProductItem from "../../components/ProductItem";
import productData from "../../data/ProductData.json";
import Couch from "../../images/about/couch.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

export default function HeroSection() {
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const shuffledData = shuffleArray(
      productData.categories.flatMap((category) => category.products)
    );
    const selectedProducts = shuffledData.slice(0, 10);

    setDisplayedProducts(selectedProducts);

    const interval = setInterval(() => {
      const shuffledDataAgain = shuffleArray(
        productData.categories.flatMap((category) => category.products)
      );
      const selectedProductsAgain = shuffledDataAgain.slice(0, 8);
      setDisplayedProducts(selectedProductsAgain);
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 728,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="bg-[#3b5d50] h-[85vh] sm:h-[90vh] w-full flex items-center justify-between px-16 sm:px-4 sm:flex sm:flex-col-reverse text-white">
        <div className="w-1/2 sm:w-full">
          <h1 className="font-bold text-3xl sm:text-lg">
            Modern Elegance, Timeless Design - Elevate Your Interior with
            Newline Limited
          </h1>
          <p className="text-sm my-4">
            Transform Your Space, Elevate Your Lifestyle. With Newline Limited,
            explore the pinnacle of modern interior design where elegance meets
            functionality. Our meticulously crafted furniture and home products
            not only redefine aesthetics but also enhance the quality of
            everyday living.
          </p>
          <div className="flex gap-4 my-6">
            <NavLink to="/" className="text-center p-4 rounded-full w-1/4  sm:w-full bg-transparent border border-white font-bold">
              Explore
            </NavLink>
          </div>
        </div>
        <div className="flex justify-end items-end">
          <img src={Couch} alt={Couch} />
        </div>
      </div>
      <div className="grid grid-cols-10 sm:grid-cols-1 mx-16 sm:mx-4 sm:my-16 place-items-center">
        <div className="w-full col-span-3">
          <h1 className="text-2xl">Crafted with excellent material.</h1>
          <p className="my-6">
            Crafted with exceptional materials, each piece showcases unmatched
            durability and aesthetic appeal, ensuring that your space is both
            beautiful and built to last.
          </p>
          <button className="p-4 rounded-full w-1/2 bg-gray-700 border border-white font-bold text-white">
            Explore
          </button>
        </div>
        <div className="p-16 sm:p-4 w-full max-w-full  flex items-center justify-center col-span-7">
          <div className=" sm:p-4 w-full">
            <Slider {...sliderSettings}>
              {displayedProducts.map((product, index) => (
                <div key={product.id} className="flex sm:grid sm:place-items-center">
                  <div className="h-[500px] sm:h-[400px]">
                    {" "}
                    <ProductItem
                      title={product.title}
                      mainImageUrl={product.details.mainImageUrl}
                      tags={product.tags}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
