import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineDown, AiOutlineSearch } from "react-icons/ai"; // Import the delete and dropdown icons from react-icons
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "./LoadingSpinner";
import EditModal from "./EditModal";
import ConfirmationDialog from "./ConfirmationDialog";

const Table = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [searchQuery, setSearchQuery] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories] = useState([]);
    const [types] = useState([]);

    useEffect(() => {
        // Fetch Categories
        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/categories`
                );
                setCategories(response.data);
            } catch (error) {
                toast.error("Failed to fetch categories:", error);
            }
        };

        fetchCategories();
    }, []); // This effect should run once on component mount

    const token = localStorage.getItem("authToken");
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    useEffect(() => {
        axios.defaults.withCredentials = true;

        axios
            .get(`${process.env.REACT_APP_SANCTUM_CSRF_URL}`)
            .then(() => {
                console.log("CSRF token set");
            })
            .catch((error) => {
                toast.error("Error fetching CSRF token:", error);
            });
    }, []);

    useEffect(() => {
        fetchProducts();
    }, []); // Empty dependency array means this effect runs once on component mount

    // If loading, show the spinner
    if (isLoading) {
        return <LoadingSpinner />;
    }

    const fetchProducts = async () => {
        try {
            setIsLoading(true); // Show loading indicator at the start

            // Fetch products from the API
            const apiUrl = `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products`;
            const response = await axios.get(apiUrl);

            // Update products state with the response data
            setProducts(response.data); // Assuming response.data contains an array of products

            // Show success message
            toast.success("User Logged in Successfully");
        } catch (error) {
            // Log and show error message
            console.error("Error updating product details:", error);
            toast.error("Failed to update product details. Please try again.");
        } finally {
            // Hide loading indicator in both success and error cases
            setIsLoading(false);
        }
    };

    const deleteProduct = async (productId) => {
        setIsLoading(true); // Start loading before the operation begins
        try {
            await axios.delete(
                `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products/${productId}`
            );
            fetchProducts(); // Re-fetch products to update the list
            toast.success("Product deleted successfully");
        } catch (error) {
            toast.error("Failed to delete product:", error);
        } finally {
            setIsLoading(false); // Stop loading regardless of success or failure
        }
    };

    const getStatusStyles = (status) => {
        switch (status) {
            case "In Stock":
                return "bg-green-100 text-green-800 text-xs";
            case "pause":
                return "bg-yellow-100 text-yellow-800";
            case "out of stock":
                return "bg-red-100 text-red-800";
            default:
                return "";
        }
    };

    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setIsEditModalOpen(true);
    };

    // Handler for search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        // You would also call your API to fetch the filtered data based on the search query
    };

    // Dummy function to simulate page change
    const handlePageChange = (pageNumber) => {
        // Here you would call your API to fetch the data for the selected page number
    };

    // Filter buttons would trigger a call to your API with the selected filter applied
    const handleFilterChange = (filterType, value) => {
        // API call to fetch data with the selected filter
    };

    const toggleDropdown = (productId) => {
        if (dropdownOpen === productId) {
            setDropdownOpen(null); // Close dropdown
        } else {
            setDropdownOpen(productId); // Open dropdown for clicked product
        }
    };

    const handleDeleteConfirmation = (productId) => {
        setShowConfirmation(true);
        setSelectedProductId(productId); // Set the ID of the product to be deleted
        setDropdownOpen(null); // Add this line to close the dropdown
    };

    // Function to handle cancellation of deletion
    const handleCancelDelete = () => {
        setShowConfirmation(false);
        setSelectedProductId(null);
    };
    const handleConfirmDelete = async () => {
        if (selectedProductId) {
            await deleteProduct(selectedProductId);
            setShowConfirmation(false);
            setSelectedProductId(null);
        }
    };

    return (
        <div className="overflow-x-auto relative mt-10">
            <h1 className="text-3xl font-bold my-4">Latest Products</h1>
            {/* Search and Filter Bar */}
            <div className="flex justify-between items-center bg-white p-4">
                <div className="flex border rounded overflow-hidden">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="px-4 py-2"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <button className="flex items-center justify-center px-4 border-l">
                        <AiOutlineSearch />
                    </button>
                </div>
                <div>
                    {/* Replace these with actual date pickers and dropdowns as needed */}
                    <button
                        onClick={() => handleFilterChange("date")}
                        className="p-2 border rounded mr-2"
                    >
                        Date
                    </button>
                </div>
            </div>
            <table className="w-full bg-white text-left">
                <thead>
                    <tr className="w-full h-16 border-gray-300 border-b py-8">
                        <th className="px-6 py-3">
                            <input type="checkbox" className="form-checkbox" />
                        </th>
                        <th className="px-4 text-sm">Title</th>
                        <th className="px-4 text-sm">Description</th>
                        <th className="px-4 text-sm">Category</th>
                        <th className="px-4 text-sm">Sub Category</th>
                        <th className="px-4 text-sm">Type</th>
                        <th className="px-4 text-sm">Status</th>
                        <th className="px-4 text-sm">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((item) => (
                        <tr key={item.id} className="border-gray-300 border-b">
                            <td className="px-6 py-4">
                                <input
                                    type="checkbox"
                                    className="form-checkbox"
                                />
                            </td>
                            <td className="p-2 text-sm">{item.title}</td>
                            <td className="p-2 text-sm overflow-hidden text-ellipsis line-clamp-4">
                                {item.description}
                            </td>
                            {/* Main Category */}
                            <td className="p-2 text-sm">
                                {item.category?.name}
                            </td>

                            <td className="p-2 text-sm">
                                {item.subcategory?.name}
                            </td>
                            {/* Assuming `item.type` is not an array and correcting the previous mistake */}
                            <td className="p-2 text-sm">{item.type?.name}</td>
                            <td className="p-2 text-sm">
                                <button
                                    className={`px-4 py-1 rounded-full text-xs ${getStatusStyles(
                                        item.status?.name
                                    )}`}
                                >
                                    {item.status?.name}
                                </button>
                            </td>
                            <td className="p-2 flex items-center">
                                <td className="px-6 py-4 flex items-center">
                                    <button className="text-sm p-2 rounded-md hover:bg-gray-100 mr-2 border border-blue-600 text-blue-400">
                                        Manage
                                    </button>
                                    <div className="relative inline-block text-left">
                                        <button
                                            onClick={() =>
                                                toggleDropdown(item.id)
                                            }
                                            className="text-sm inline-flex justify-center w-full p-2 rounded-md border border-blue-600 text-blue-400 hover:bg-gray-100 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue"
                                        >
                                            Actions
                                            <AiOutlineDown
                                                className="ml-2 -mr-1 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </button>
                                        {dropdownOpen === item.id && (
                                            <div className="origin-top-right absolute z-50 right-0 mt-2 w-36 rounded-md shadow-lg bg-white">
                                                <button
                                                    onClick={() =>
                                                        handleEditClick(item)
                                                    }
                                                    className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                >
                                                    Edit
                                                </button>
                                                <div className="py-1">
                                                    <button
                                                        onClick={() =>
                                                            handleDeleteConfirmation(
                                                                item.id
                                                            )
                                                        }
                                                        className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="bg-white p-4 flex justify-between items-center">
                <span>Showing 1-10 of 100 results</span>
                <div>
                    <button
                        onClick={() => handlePageChange(1)}
                        className="p-2 border rounded mr-2"
                    >
                        First
                    </button>
                    <button
                        onClick={() => handlePageChange(2)}
                        className="p-2 border rounded mr-2"
                    >
                        Prev
                    </button>
                    {/* Render page numbers dynamically based on the data */}
                    <button
                        onClick={() => handlePageChange(3)}
                        className="p-2 border rounded mr-2"
                    >
                        3
                    </button>
                    <button
                        onClick={() => handlePageChange(4)}
                        className="p-2 border rounded"
                    >
                        Next
                    </button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isEditModalOpen && (
                <EditModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    product={selectedProduct}
                    categories={categories}
                    subCategories={subCategories}
                    type={types}
                    onSubmit={fetchProducts}
                    fetchProducts={fetchProducts}
                />
            )}
            <ConfirmationDialog
                isOpen={showConfirmation}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default Table;
