import React from "react";
import { NavLink } from "react-router-dom";

export default function ShowCase() {
    return (
        <div className="relative">
            <div className="bg-showcase h-[100vh] sm:h-[75vh] bg-center w-full bg-no-repeat bg-cover text-center p-16 sm:p-4 flex  justify-center items-center">
                <div>
                    <div className="my-16 sm:my-8 text-white w-full flex justify-center items-center">
                        <h1 className="font-bold text-3xl w-1/2 sm:w-full">
                            Embrace Unique Style With Minimalistic Approach
                        </h1>
                    </div>
                    <p className="text-white">Looking For ideas?</p>
                    <div className="my-6 w-full">
                        <NavLink
                            to="/kitchen-&-wardrobes/kitchen-cabinets"
                            className="px-4 py-2 border border-white text-white my-6 w-2/5 sm:w-4/5 transition duration-300 ease-in-out transform hover:bg-white hover:text-black hover:scale-105"
                        >
                            Get Inspirations
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="m-16 sm:m-4 grid gap-y-4">
                <div className="bg-office h-[45vh] bg-center w-full bg-no-repeat bg-cover text-left px-16 sm:px-4 flex items-center relative">
                    {/* Background with opacity */}
                    <div className="absolute inset-0 bg-black opacity-60"></div>
                    <div className="text-white w-full grid z-10 relative">
                        <h1 className="font-bold text-xl w-2/5 sm:w-full my-4">
                            Executive Elegance: Office Furniture
                        </h1>
                        <p className="text-sm w-4/5 sm:w-full">
                            Craft a workspace that exudes confidence and
                            comfort. Explore our selection of premium office
                            furniture designed for the discerning professional.
                        </p>
                        <div className="my-6 w-full">
                            <NavLink
                                to="/office-furniture/desks/managerial-executive-desks"
                                className="px-4 py-2 border border-white text-white my-6 w-1/5 sm:w-1/2 transition duration-300 ease-in-out transform hover:bg-white hover:text-black hover:scale-105"
                            >
                                Explore
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="bg-student h-[45vh] bg-center w-full bg-no-repeat bg-cover text-left px-16 sm:px-4 flex items-center relative">
                    {/* Background with opacity */}
                    <div className="absolute inset-0 bg-black opacity-60"></div>
                    <div className="text-white w-full grid z-10 relative">
                        <h1 className="font-bold text-xl w-2/5 sm:w-full my-4">
                            Learning in Style: School Furniture
                        </h1>
                        <p className="text-sm w-4/5 sm:w-full">
                            Foster an environment where young minds can
                            flourish. Discover our range of ergonomic and
                            durable school furniture, built for the modern
                            student.
                        </p>
                        <button className="px-4 py-2 border border-white text-white my-6 w-1/5 sm:w-1/2 transition duration-300 ease-in-out transform hover:bg-white hover:text-black hover:scale-105">
                            Explore
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
