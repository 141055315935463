import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import HeroSection from "../sections/homepage/HeroSection";
import TopNavigationBar from "../components/TopNavigationBar";
import About from "../sections/homepage/About";
import ProductExplore from "../sections/homepage/ProductExplore";
import ShowCase from "../sections/homepage/ShowCase";
import ExtraInfo from "../sections/homepage/ExtraInfo";
import Newsletter from "../sections/homepage/Newsletter";
import Footer from "../components/Footer";
import AdvertisementCard from "../components/AdvertisementCard";
import ProductsList from "../components/ProductsList";
import FloatingButtons from "../components/FloatingButtons";

export default function Homepage() {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div>
            <Helmet>
                <title>
                    Newline Limited - Premium Office and Home Furniture in Kenya
                </title>
                <meta
                    name="description"
                    content="Newline Limited specializes in crafting timeless elegance in office furniture, home decor, and kitchen & wardrobe solutions. Discover our high-quality, locally crafted furniture designed for comfort and sophistication."
                />
                <meta
                    name="keywords"
                    content="office furniture Kenya, custom kitchen cabinets, home decor, school furniture, commercial interiors, Nairobi furniture, high-quality furniture, interior design services, luxury furniture Kenya"
                />
                <link rel="canonical" href="https://www.newlinelimited.com/" />
                <meta
                    property="og:title"
                    content="Newline Limited - Premium Office and Home Furniture in Kenya"
                />
                <meta
                    property="og:description"
                    content="Explore Newline Limited for global quality craftsmanship in office furniture, kitchens, wardrobes, and home interiors. Tailored to your style and comfort."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://www.newlinelimited.com/"
                />
                <meta property="og:image" content="URL_TO_IMAGE" />
                <meta
                    property="newline:facebook"
                    content="https://www.facebook.com/newlineke"
                />
                <meta
                    property="newline:twitter"
                    content="https://x.com/ltd_newline"
                />
                <meta
                    property="newline:instagram"
                    content="https://www.instagram.com/newline_ltd"
                />
                <meta
                    property="newline:linkedin"
                    content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
                />
                <meta
                    property="newline:tiktok"
                    content="https://www.tiktok.com/@newlinelimited"
                />
            </Helmet>
            <TopNavigationBar textColor="text-white" />
            <HeroSection />
            <About />
            <ProductExplore />
            <ProductsList isLoading={isLoading} setIsLoading={setIsLoading} />
            <ShowCase />
            <ExtraInfo />
            <Newsletter />
            <FloatingButtons/>
            <AdvertisementCard />
            <Footer backgroundColor="bg-gray-100" />
        </div>
    );
}
