import React from "react";
import TopNavigationBar from "../../../components/TopNavigationBar";
import productData from "../../../data/office/desks/OfficeDesks.json";
import Image from "../../../images/homepage/easy_desk_3drawers.webp";
import Footer from "../../../components/Footer";
import HeroSection from "./HeroSection";
import ProductItem from "../../../components/ProductItem";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function OfficeDesks() {
  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>
          Office Desks | Functional & Stylish Workspace Solutions | Newline
          Limited
        </title>
        <meta
          name="description"
          content="Explore Newline Limited's office desks, tailored for functionality and style. From executive desks to compact solutions, find the perfect desk to enhance your workspace aesthetics and productivity."
        />
        <meta
          name="keywords"
          content="office desks, executive desks, compact office solutions, stylish workspace, Newline Limited, office furniture Kenya, ergonomic desks, modern office design"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/desks/office-desks"
        />
        <meta
          property="og:title"
          content="Office Desks | Functional & Stylish Workspace Solutions | Newline Limited"
        />
        <meta
          property="og:description"
          content="Discover the perfect blend of functionality and style with Newline Limited's range of office desks. Elevate your workspace today."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/desks/office-desks"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="URL_TO_FEATURED_OFFICE_DESK_IMAGE" />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigationBar />
      <HeroSection
        title="Office Desks"
        description="Searching for the perfect office desk that marries functionality with style? Our range offers various designs to optimize your workspace, from spacious executive desks to compact solutions for smaller areas. We're dedicated to assisting you in finding an office desk that not only suits your work habits and technology needs but also enhances the visual appeal of your office. Let us guide you in selecting a desk that stands at the heart of your productive and stylish workspace."
        imageURL={Image}
        imageHeight="w-4/5"
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 m-16 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}
