import React from "react";
import Gallery from "../../images/about/thumbnail.png";

export default function OtherServices() {
  return (
    <div className="px-16 my-36 sm:px-4">
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-8">
        <img src={Gallery} alt={Gallery} />
        <div>
          <h1 className="text-3xl w-4/5">
            We Help You Make Modern Interior Design
          </h1>
          <p className="my-6">
            Elevate your space with our modern interior design solutions,
            tailored to bring your vision to life with style and functionality.
            Our expert team ensures your environment is transformed into a
            contemporary haven.
          </p>
          <div className="grid grid-cols-2 gap-3">
            <li>
              Customized Furniture Solutions - Tailored designs to fit your
              specific space and style preferences.
            </li>
            <li>
              High-Quality Material Selection - Only the best materials are
              used, ensuring durability and aesthetic appeal.
            </li>
            <li>
              Comprehensive Design Consultation - From concept to completion,
              our experts guide you every step of the way.
            </li>
            <li>
              Post-Purchase Support - Exceptional after-sales service, including
              design advice and product care tips.
            </li>
          </div>
          <button className="p-4 rounded-full my-6 w-1/4 sm:w-1/2 bg-gray-700 border border-white font-bold text-white">
            Explore
          </button>
        </div>
      </div>
    </div>
  );
}
