import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function ContactForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        fname: "",
        lname: "",
        phone: "",
        cname: "N/A", // Default value set to N/A
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios
            .get(process.env.REACT_APP_SANCTUM_CSRF_URL)
            .then(() => {
                console.log("CSRF token fetched successfully.");
            })
            .catch((error) => {
                console.error("Error fetching CSRF token:", error);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // Also clear errors for this field
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: false,
            }));
        }
    };

    const validateForm = () => {
        let newErrors = {};
        let isValid = true;

        if (!formData.fname) {
            newErrors.fname = true;
            isValid = false;
        }
        if (!formData.lname) {
            newErrors.lname = true;
            isValid = false;
        }
        if (!formData.phone) {
            newErrors.phone = true;
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = true;
            isValid = false;
        } else {
            const emailRegex = /\S+@\S+\.\S+/;
            if (!emailRegex.test(formData.email)) {
                newErrors.email = true;
                isValid = false;
            }
        }
        if (!formData.message) {
            newErrors.message = true;
            isValid = false;
        }

        setErrors(newErrors);

        if (!isValid) {
            toast.error("Please fill in all required fields correctly.");
        }

        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LARAVEL_APP_API_URL}/contact`,
                formData,
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            if (response.status === 200) {
                // Adjust the message here to what you specifically want
                toast.success("Message sent successfully!");
            } else {
                toast.error("There was an error. Please try again later.");
            }
        } catch (error) {
            toast.error(
                error.response?.data?.message ||
                    "There was a problem submitting your form. Please try again later."
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Function to return common input classes, adding error class if needed
    const getInputClass = (name) => {
        // Common classes for all input fields
        let classes = "mt-1 p-2 border rounded-md w-full";

        // Append error class if there's an error for this field
        if (errors[name]) {
            classes += " border-red-500"; // If there's an error, make the border red
        } else {
            classes += " border-gray-300"; // Otherwise, use the default border color
        }

        return classes;
    };  

    return (
        <div className="p-16 sm:p-4 bg-gray-50">
           {isLoading && <LoadingSpinner />}
            <div className="grid grid-cols-3 sm:grid-cols-1 sm:w-full w-4/5 sm:gap-6 mx-auto">
                <div className="flex gap-6 items-center">
                    <div className="h-16 sm:h-10 w-16 sm:w-10 sm:text-sm flex items-center justify-center rounded-md bg-black">
                        <box-icon
                            type="solid"
                            name="map"
                            color="white"
                        ></box-icon>
                    </div>
                    <p className="w-1/2">Loita Street, Chester House</p>
                </div>
                <div className="flex gap-6 items-center">
                    <div className="h-16 sm:h-10 w-16 sm:w-10 flex items-center justify-center rounded-md bg-black">
                        <box-icon
                            name="envelope"
                            type="solid"
                            color="white"
                        ></box-icon>
                    </div> 
                    <p className="w-1/2">info@newline.co.ke</p>
                </div>
                <div className="flex gap-6 items-center">
                    <div className="h-16 sm:h-10 w-16 sm:w-10 flex items-center justify-center rounded-md bg-black">
                        <box-icon
                            type="solid"
                            name="phone"
                            color="white"
                        ></box-icon>
                    </div>
                    <p className="w-1/2 grid">
                        <span>0713 235 559</span>
                        <span>0713 235 563</span>
                        <span>020 3315 110</span>
                    </p>
                </div>
            </div>
            <div className="mt-8 bg-white shadow-md rounded-lg p-8">
                {/* Modify your form's onSubmit to use handleSubmit */}
                <form
                    onSubmit={handleSubmit}
                    className="grid grid-cols-2 md:grid-cols-2 gap-4"
                >
                    <div>
                        <label
                            htmlFor="fname"
                            className="block text-sm font-medium text-gray-700"
                        >
                            First Name
                        </label>
                        <input
                            type="text"
                            name="fname"
                            value={formData.fname}
                            onChange={handleInputChange}
                            className={getInputClass("fname")}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="lname"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lname"
                            value={formData.lname}
                            onChange={handleInputChange}
                            className={getInputClass("lname")}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Phone Number
                        </label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            className={getInputClass("phone")}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="cname"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Company Name
                        </label>
                        <input
                            type="text"
                            name="cname"
                            value={formData.cname}
                            onChange={handleInputChange}
                            className={getInputClass("cname")}
                        />
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={getInputClass("email")}
                        />
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="message"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Message
                        </label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            cols="30"
                            rows="10"
                            className={getInputClass("message")}
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-dark-red p-4 text-white w-1/5 sm:w-full"
                    >
                        Submit
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}
