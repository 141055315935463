import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";
import DropdownSelect from "./DropdownSelect";
import CustomModal from "./CustomModal";

const EditModal = ({
    isOpen,
    onClose,
    product,
    onSubmit, // Assuming you'll use this for handling form submission
}) => {
    const [formData, setFormData] = useState({
        title: product?.title || "",
        description: product?.description || "",
        main: undefined, // This should ideally be set if you're editing an existing product with an image
        category: product?.category?.name || "",
        subCategory: product?.subcategory?.name || "",
        type: product?.type?.name || "",
        mainImageURL: "",
    });

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(
        product?.category_id?.toString() || ""
    );
    const [selectedSubCategory, setSelectedSubCategory] = useState(
        product?.subcategory_id?.toString() || ""
    );
    const [selectedType, setSelectedType] = useState(
        product?.type_id?.toString() || ""
    );
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const token = localStorage.getItem("authToken");
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    const handleSelectChange = (setter) => (event) => {
        setter(event.target.value);
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;
        axios
            .get(`${process.env.REACT_APP_SANCTUM_CSRF_URL}`)
            .then(() => {
                console.log("CSRF token set");
            })
            .catch((error) => {
                console.error("Error fetching CSRF token:", error);
            });
    }, []);

    useEffect(() => {
        if (product) {
            const mainImageUrl = product.images?.find((image) => !image.url.includes("thumbnails"))
            ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${product.images[0].url}`
            : "";
            setFormData({
                title: product.title || "",
                description: product.description || "",
                mainImageURL: mainImageUrl,
                category: product.category ? product.category.name : "",
                subCategories: product.subcategory
                    ? product.subcategory.name
                    : "",
                type: product.type ? product.type.name : "",
            });
            setSelectedCategory(product.category_id?.toString() || "");
            setSelectedSubCategory(product.subcategory_id?.toString() || "");
            setSelectedType(product.type_id?.toString() || "");
        }
    }, [product]);
    useEffect(() => {
        const fetchInitialData = async () => {
            setIsLoading(true);
            try {
                // Fetch categories initially
                const categoriesResponse = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/categories`
                );
                setCategories(categoriesResponse.data);
            } catch (error) {
                console.error("Failed to fetch initial data", error);
            }
            setIsLoading(false);
        };

        fetchInitialData();
    }, []);

    useEffect(() => {
        const fetchSubcategories = async () => {
            if (!selectedCategory) {
                setSubCategories([]);
                return;
            }
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/categories/${selectedCategory}/subcategories`
                );
                setSubCategories(response.data.children || []);
            } catch (error) {
                console.error("Failed to fetch subcategories", error);
                setSubCategories([]);
            }
            setIsLoading(false);
        };

        fetchSubcategories();
    }, [selectedCategory]);

    useEffect(() => {
        const fetchTypes = async () => {
            if (!selectedSubCategory) {
                setTypes([]);
                return;
            }
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/subcategories/${selectedSubCategory}/types`
                );
                setTypes(response.data || []);
            } catch (error) {
                console.error("Failed to fetch types", error);
                setTypes([]);
            }
            setIsLoading(false);
        };

        fetchTypes();
    }, [selectedSubCategory]);

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // If loading, show the spinner
    if (isLoading) {
        return <LoadingSpinner />;
    }

    const validateImages = () => {
        if (formData.main && formData.main.size > 2048 * 1024) {
            toast.error("Main image must be 2MB or less.");
            return false;
        }

        const oversizedThumbnails = formData.thumbnails?.filter(
            (file) => file.size > 2048 * 1024
        );
        if (oversizedThumbnails?.length > 0) {
            toast.error("All thumbnails must be 2MB or less.");
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm() || !validateImages()) return;
        if (!validateForm()) return; // Exit if validation fails
        const updatedProductData = {
            id: product.id,
            ...formData,
            category_id: selectedCategory,
            subcategory_id: selectedSubCategory,
            type_id: selectedType, // Make sure this is a string
            images:
                product.images?.map((img) => ({ id: img.id, url: img.url })) ??
                [],
        };

        // Prepare FormData for file upload
        const productFormData = new FormData();
        productFormData.append("title", formData.title);
        productFormData.append("description", formData.description);
        productFormData.append("category_id", selectedCategory);
        productFormData.append("subcategory_id", selectedSubCategory);
        productFormData.append("type_id", selectedType);

        // Include the main image file in the FormData if it exists
        if (formData.main) {
            productFormData.append("main", formData.main);
        }

        // Append other product details to FormData as needed...

        try {
            await axios.put(
                `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products/${product.id}`,
                updatedProductData
            );
            // If the request is successful, show a success message
            toast.success(`Product updated successfully!`);

            onSubmit(updatedProductData); // Call the passed onSubmit function to refresh the products list in the parent component
            onClose(); // Close the modal form
        } catch (error) {
            // If the request fails, log the error and show a failure message
            console.error("Error during product update:", error);
            toast.error(`Failed to update the product. Please try again.`);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.title.trim()) {
            newErrors.title = "Title is required.";
            toast.error("Title is required.");
            isValid = false;
        }
        if (!selectedCategory) {
            newErrors.category = "Category is required.";
            toast.error("Category is required.");
            isValid = false;
        }
        if (!formData.description.trim()) {
            newErrors.description = "Description is required.";
            toast.error("Description is required.");
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleMainImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size > 2048 * 1024) {
            // 2MB limit
            toast.error("Main image must be 2MB or less.");
        } else {
            setFormData({ ...formData, main: file });
        }
    };
    return (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-50 ">
            <CustomModal
                isOpen={isOpen}
                onClose={onClose}
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        zIndex: "50", // Ensure this is high enough to cover other elements
                    },
                    content: {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        border: "1px solid #ccc",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "0.5rem", // Tailwind's rounded-lg equivalent
                        outline: "none",
                        padding: "2rem", // Tailwind's p-8 equivalent
                        width: "75%",
                        height: "80%",
                    },
                }}
                ariaHideApp={false} // Avoids app element warning in development, adjust as needed
            >
                <form className="space-y-6" onSubmit={handleSubmit}>
                    {/* Image Upload */}
                    <div className="grid grid-cols-7 gap-4 items-start">
                        <div className="grid col-span-3 h-full border border-gray-300 justify-center items-center">
                            <div className="h-full flex justify-start items-start p-4">
                                <div className="pt-6">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Upload Image (Max 2MB)
                                    </label>
                                    <input
                                        type="file"
                                        name="main"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                                        onChange={handleMainImageChange} // Use the new handler here
                                    />
                                    {errors.main && (
                                        <p className="text-red-500 text-xs mt-1">
                                            {errors.main}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="relative">
                                    {formData.mainImageURL && (
                                        <img
                                            src={formData.mainImageURL}
                                            alt="Main Product"
                                            className="rounded-lg h-64 w-full object-cover object-center"
                                        />
                                    )}
                                    {/* <button
                                        onClick={deleteMainImage}
                                        type="button"
                                        className="bg-white p-1 shadow-md rounded-sm absolute top-4 right-4"
                                    >
                                        <MdDelete className="text-red-500" />
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="block">
                                <span className="text-gray-700">
                                    Product Title
                                </span>
                                <input
                                    type="text"
                                    name="title" // Ensure the name matches the key in formData
                                    value={formData.title} // Bind input value to formData
                                    onChange={handleFormChange} // Use the generic handleChange method
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                                />
                                {errors.title && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {errors.title}
                                    </p>
                                )}
                            </label>
                                                {/* Dropdowns */}
                    <div className="grid grid-cols-2 gap-4 my-6">
                        <DropdownSelect
                            id="category"
                            label="Category"
                            value={selectedCategory}
                            onChange={handleSelectChange(setSelectedCategory)}
                            options={categories}
                            disabled={false}
                        />

                        <DropdownSelect
                            id="subCategory"
                            label="Sub Category"
                            value={selectedSubCategory}
                            onChange={handleSelectChange(
                                setSelectedSubCategory
                            )}
                            options={subCategories} // This should ideally be a separate list
                            disabled={!selectedCategory}
                        />
                        <DropdownSelect
                            id="type"
                            label="Type"
                            value={selectedType}
                            onChange={handleSelectChange(setSelectedType)}
                            options={types}
                            disabled={!selectedSubCategory}
                        />
                    </div>
                        </div>
                    </div>

                    {/* Description */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleFormChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                            rows="4"
                        ></textarea>
                        {errors.description && (
                            <p className="text-red-500 text-xs mt-1">
                                {errors.description}
                            </p>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-max-w flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-dark-red hover:bg-light-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Update
                    </button>
                </form>
            </CustomModal>
        </div>
    );
};

export default EditModal;
