import React from "react";
import { NavLink } from "react-router-dom";

export default function ProductExplore() {
    return (
        <div className="px-16 grid grid-cols-3 sm:grid-cols-1 sm:px-4 my-6">
            <div className="h-[90vh] sm:h-[65vh] w-full bg-no-repeat bg-cover bg-bed relative">
                {/* Background with opacity */}
                <div className="absolute inset-0 bg-black opacity-60"></div>

                {/*Card Number*/}
                <div className="absolute top-0 left-0 m-8 h-12 w-12 bg-white text-black text-2xl font-bold flex justify-center items-center rounded-full">
                    <p>01</p>
                </div>
                {/* Content container without opacity */}
                <div className="relative z-10 px-8 py-4 grid place-items-end h-full">
                    <div className="text-white">
                        <p>
                            Customize your product or be assisted with our
                            in-house experts
                        </p>
                        <div className="my-6 w-full">
                            <NavLink
                                to="/home-furniture/bedroom/beds"
                                className="border-2 w-full border-white px-4 py-3 my-4 hover:bg-white hover:text-black transition-all"
                            >
                                Explore
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h-[90vh] sm:h-[65vh] w-full bg-no-repeat bg-cover bg-chair-showcase relative">
                {/* Background with opacity */}
                <div className="absolute inset-0 bg-black opacity-60"></div>
                {/*Card Number*/}
                <div className="absolute top-0 left-0 m-8 h-12 w-12 bg-white text-black text-2xl font-bold flex justify-center items-center rounded-full">
                    <p>02</p>
                </div>
                {/* Content container without opacity */}
                <div className="relative z-10 px-8 py-4 grid place-items-end h-full">
                    <div className="text-white">
                        <p>
                            High Quality material and minimalistic design for
                            offices or corporate businesses
                        </p>
                        <div className="my-6 w-full">
                            <NavLink
                                to="/office-furniture/chairs/reception-chairs"
                                className="border-2 w-3/5 border-white px-4 py-3 my-4 hover:bg-white hover:text-black transition-all"
                            >
                                Explore
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="h-[45vh] bg-dark-red grid text-white p-10">
                    <p className="flex gap-6">
                        <span className="font-bold">01</span>
                        <span>Minimalistic</span>
                    </p>
                    <p className="flex gap-6">
                        <span className="font-bold">02</span>
                        <span>Aesthetic</span>
                    </p>
                    <p className="flex gap-6">
                        <span className="font-bold">03</span>
                        <span>High Quality</span>
                    </p>
                    <p className="flex gap-6">
                        <span className="font-bold">04</span>
                        <span>Unique</span>
                    </p>
                    <p className="flex gap-6">
                        <span className="font-bold">05</span>
                        <span>Affordable</span>
                    </p>
                </div>
                <div className="h-[45vh] w-full bg-no-repeat bg-cover bg-sofa relative">
                    {/* Background with opacity */}
                    <div className="absolute inset-0 bg-black opacity-60"></div>
                    {/*Card Number*/}
                    <div className="absolute top-0 left-0 m-8 h-12 w-12 bg-white text-black text-2xl font-bold flex justify-center items-center rounded-full">
                        <p>03</p>
                    </div>
                    {/* Content container without opacity */}
                    <div className="relative z-10 px-8 py-4 grid place-items-end h-full">
                        <div className="text-white">
                            <p>
                                Set up your home, we can help you and guide with
                                interior designs come to our office and get
                                inspiration from our show room.
                            </p>
                            <div className="my-6 w-full">
                                <NavLink
                                    to="/home-furniture/living-room/ottomans"
                                    className="border-2 w-3/5 border-white px-4 py-3 my-4 hover:bg-white hover:text-black transition-all"
                                >
                                    Explore
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
