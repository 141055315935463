import React from "react";

export default function Newsletter() {
  return (
    <div className="bg-newsletter bg-center bg-cover h-[90vh] sm:h-[60vh] relative">
      {/* Background with opacity */}
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="relative z-10 grid place-items-center h-full w-full">
        <div>
          <h1 className="font-bold text-3xl my-6 text-white">Join Our Community</h1>
          <input
            type="text"
            name=""
            id=""
            className="border border-white p-4 w-full bg-transparent"
            placeholder="Enter Your Email"
          />
        </div>
      </div>
    </div>
  );
}
