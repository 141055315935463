import React from 'react';
import { motion } from 'framer-motion';

export default function LoadingSpinner() {
 const spinnerVariants = {
  animate: {
    scale: [1, 0.8, 1], // Pulsing effect
    borderRadius: ["20%", "50%", "20%", "80%", "20%"], // Morphing shapes
    rotate: [0, 360], // Full rotation
    boxShadow: ["0 0 20px rgba(0, 0, 0, 0.25)", "0 0 30px rgba(0, 0, 0, 0.35)", "0 0 20px rgba(0, 0, 0, 0.25)"], // Shadow pulse
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
    }
  }
};

  return (
    <div className="fixed inset-0 bg-white bg-opacity-90 flex justify-center items-center z-50">
      <motion.div
        className="w-16 h-16 bg-gradient-to-r from-amber-700 to-dark-red"
        style={{
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.25)',
        }}
        variants={spinnerVariants}
        animate="animate"
      />
    </div>
  );
}
