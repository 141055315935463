import React from "react";
import 'chart.js/auto';


export default function DetailedProductCard({ value, type, icon: Icon}) {

  return (
    <div className="rounded-xl p-6 bg-white shadow-lg grid gap-4">
      <div className="flex justify-between items-center">
        <h1>{type}</h1>
        <div className="border border-black p-2 rounded-md">
          {Icon ? <Icon /> : null}
        </div>
      </div>
      <p className="text-4xl font-bold my-2">{value}</p>
    </div>
  );
}
