import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import WebLogo from "../images/homepage/logo.png";
import {
    FaArrowLeft,
    FaTimesCircle,
    FaChevronCircleRight,
} from "react-icons/fa";

import { useNavigate, NavLink } from "react-router-dom";

const SideMenu = ({ isOpen, onClose }) => {
    const [activeMenu, setActiveMenu] = useState({
        title: null,
        subLinks: [],
        activeSubLinkTitle: null,
        subSubLinks: [],
    });
    const controls = useAnimation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape" && isOpen) {
                onClose();
                resetMenu();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [isOpen, onClose]);

    useEffect(() => {
        controls.start(isOpen ? "open" : "closed");
    }, [isOpen, controls]);

    const handleMenuClick = (item, isSubLink = false) => {
        if (!isSubLink && item.subLinks) {
            setActiveMenu({
                ...activeMenu,
                title: item.title,
                subLinks: item.subLinks,
                activeSubLinkTitle: null,
                subSubLinks: [],
            });
        } else if (isSubLink && item.subLinks) {
            setActiveMenu({
                ...activeMenu,
                activeSubLinkTitle: item.title,
                subSubLinks: item.subLinks,
            });
        } else {
            // Build path dynamically based on whether it's a sub-link or a sub-sub-link
            let path = "";
            if (activeMenu.title && !activeMenu.activeSubLinkTitle) {
                // For sub-links, use the main menu title and the sub-link title
                path = `/${activeMenu.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}/${item.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`;
            } else if (activeMenu.activeSubLinkTitle) {
                // For sub-sub-links, include main menu, sub-link, and sub-sub-link titles
                path = `/${activeMenu.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}/${activeMenu.activeSubLinkTitle
                    .toLowerCase()
                    .replace(/\s+/g, "-")}/${item.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`;
            } else {
                // Directly navigate to the route for items without subLinks
                path = `/${item.title.toLowerCase().replace(/\s+/g, "-")}`;
            }
            navigate(path);
            onClose();
        }
    };

    const backToMainMenu = () => {
        setActiveMenu({
            title: null,
            subLinks: [],
            activeSubLinkTitle: null,
            subSubLinks: [],
        });
    };

    const backToSubMenu = () => {
        setActiveMenu({
            ...activeMenu,
            activeSubLinkTitle: null,
            subSubLinks: [],
        });
    };

    const resetMenu = () => {
        setActiveMenu({
            title: null,
            subLinks: [],
            activeSubLinkTitle: null,
            subSubLinks: [],
        });
    };

    const slideInVariants = {
        hidden: {
            x: "-100%",
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { type: "spring", stiffness: 300, damping: 30 },
        },
        exit: {
            x: "-100%",
            opacity: 0,
            transition: { duration: 0.2 },
        },
    };

    const menuItems = [
        {
            title: "Office Furniture",
            subLinks: [
                {
                    title: "Chairs",
                    subLinks: [
                        { title: "High Back Chairs" },
                        { title: "Mid Back Chairs" },
                        { title: "Visitor's Chairs" },
                        { title: "Reception Chairs" },
                    ],
                },
                {
                    title: "Desks",
                    subLinks: [
                        { title: "Office Desks" },
                        { title: "Managerial-Executive Desks" },
                        { title: "Workstations" },
                        { title: "Conference Tables" },
                        { title: "Reception Desks" },
                    ],
                },
                {
                    title: "Coffee Tables",
                },
                {
                    title: "Cabinets",
                },
            ],
        },
        {
            title: "Home Furniture",
            subLinks: [
                {
                    title: "Bedroom",
                    subLinks: [
                        { title: "Beds" },
                        { title: "Bedside Cabinets" },
                    ],
                },
                {
                    title: "Dining Room",
                    subLinks: [{ title: "Dining Tables" }],
                },
                {
                    title: "Living Room",
                    subLinks: [
                        { title: "Coffee Tables" },
                        { title: "Ottomans" },
                        { title: "Sofas" },
                        { title: "Pillows" },
                        { title: "TV Stands" },
                    ],
                },
                {
                    title: "Study Room",
                    subLinks: [{ title: "Book Shelves" }],
                },
            ],
        },
        {
            title: "Kitchen & Wardrobes",
            subLinks: [
                {
                    title: "Kitchen Cabinets",
                },
                {
                    title: "Wardrobes",
                },
            ],
        },
        {
            title: "Commercial Interiors",
        },
        {
            title: "School & University",
        },
        // {
        //     title: "Hospitality",
        //     subLinks: [
        //         {
        //             title: "Hotel Furniture",
        //         },
        //         {
        //             title: "Restaurant/ Cafe Furniture",
        //         },
        //     ],
        // },
        {
            title: "About Us",
        },
        {
            title: "Contact Us",
        },
    ];

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={slideInVariants}
                    className="uppercase text-sm fixed top-0 left-0 h-screen w-[90%] bg-white shadow-lg z-50 overflow-y-hidden"
                >
                    <div className="flex items-center justify-between w-full px-6 my-4">
                        <NavLink to="/">
                            <img
                                src={WebLogo}
                                alt="Newline Logo"
                                className="w-36"
                            />
                        </NavLink>
                        <button
                            onClick={() => {
                                if (activeMenu.activeSubLinkTitle) {
                                    backToSubMenu();
                                } else if (activeMenu.title) {
                                    backToMainMenu();
                                } else {
                                    onClose();
                                }
                            }}
                            className="p-4"
                        >
                            <div>
                                {activeMenu.title ? (
                                    <FaArrowLeft size="24" />
                                ) : (
                                    <FaTimesCircle size="24" />
                                )}
                            </div>
                        </button>
                    </div>
                    {/* Main Menu */}
                    <AnimatePresence>
                        {activeMenu.title === null && (
                            <motion.nav
                                key="mainMenu"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={slideInVariants}
                                className="px-6 w-[95%]"
                            >
                                {menuItems.map((item, index) => (
                                    <div
                                        key={index}
                                        className=" uppercase text-sm cursor-pointer p-2 grid"
                                        onClick={() => handleMenuClick(item)}
                                    >
                                        <div className="flex justify-between items-center">
                                            {item.title}
                                            {item.subLinks && (
                                                <FaChevronCircleRight size="24" />
                                            )}
                                        </div>
                                        <hr className="my-4" />
                                    </div>
                                ))}
                            </motion.nav>
                        )}
                    </AnimatePresence>

                    {/* Sub-menu */}
                    <AnimatePresence>
                        {activeMenu.title !== null &&
                            activeMenu.activeSubLinkTitle === null && (
                                <motion.nav
                                    key="subMenu"
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    variants={slideInVariants}
                                    className="px-5"
                                >
                                    {activeMenu.subLinks.map(
                                        (subLink, index) => (
                                            <div
                                                key={index}
                                                className="uppercase text-sm cursor-pointer p-2 grid"
                                                onClick={() =>
                                                    handleMenuClick(
                                                        subLink,
                                                        true
                                                    )
                                                }
                                            >
                                                <div className="flex justify-between items-center">
                                                    {subLink.title}
                                                    {subLink.subLinks && (
                                                        <FaChevronCircleRight />
                                                    )}
                                                </div>
                                                <hr className="my-4" />
                                            </div>
                                        )
                                    )}
                                </motion.nav>
                            )}
                    </AnimatePresence>

                    {/* Sub-Sub-menu */}
                    <AnimatePresence>
                        {activeMenu.activeSubLinkTitle !== null && (
                            <motion.nav
                                key="subSubMenu"
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={slideInVariants}
                                className="px-5"
                            >
                                {activeMenu.subSubLinks.map(
                                    (subSubLink, index) => (
                                        <NavLink
                                            key={index}
                                            to={`/${activeMenu.title
                                                .toLowerCase()
                                                .replace(
                                                    /\s+/g,
                                                    "-"
                                                )}/${activeMenu.activeSubLinkTitle
                                                .toLowerCase()
                                                .replace(
                                                    /\s+/g,
                                                    "-"
                                                )}/${subSubLink.title
                                                .toLowerCase()
                                                .replace(/\s+/g, "-")}`}
                                            className="block p-2"
                                            onClick={onClose}
                                        >
                                            {subSubLink.title}
                                            <hr className="my-4" />
                                        </NavLink>
                                    )
                                )}
                            </motion.nav>
                        )}
                    </AnimatePresence>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SideMenu;
