import React from "react";

export default function HeroSection() {
    return (
        <div className="relative flex flex-col place h-screen sm:h-[100vh]">
            <div className="bg-commercial bg-no-repeat bg-cover bg-center absolute top-0 left-0 h-screen">
                <div className="absolute h-full w-full bg-black opacity-65 flex justify-center place-items-center"></div>
                <div className="w-full h-full flex justify-center items-center">
                    <div className="text-white  z-30 relative  text-center">
                        {" "}
                        <h1 className="font-bold text-3xl sm:text-lg my-6">
                            UNLEASH THE ART OF COMMERCIAL INTERIORS DESIGN
                        </h1>
                        <p className="px-16 sm:p-4">
                            At Newline Interiors, we recognize that commercial
                            spaces are more than just places of business; they
                            are the epicenters of brand identity, employee
                            well-being, and customer experience. Our vision is
                            to revolutionize commercial interiors, from
                            corporate offices to retail stores, creating
                            environments that are not only visually compelling
                            but also profoundly functional and adaptive to the
                            needs of modern businesses. Delve into our vast
                            array of design solutions and find the ideal
                            ambiance that aligns with your company's ethos and
                            operational requirements. Allow us to assist you in
                            "UNLEASHING THE ART OF COMMERCIAL INTERIORS DESIGN"
                            and transforming your commercial space into a beacon
                            of innovation, efficiency, and unparalleled
                            sophistication.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
