import React, { useState, useEffect } from "react";
import { HiOutlinePaperAirplane } from "react-icons/hi";
import { motion } from "framer-motion";

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > window.innerHeight) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const variants = {
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0 },
    };

    return isVisible ? (
        <motion.button
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            variants={variants}
            onClick={scrollToTop}
            className="w-12 h-12 rounded-full bg-dark-red shadow-lg shadow-gray-500 z-30"
        >
            <div className="relative w-full h-full flex justify-center items-center">
                <HiOutlinePaperAirplane color="white" size={24} />
            </div>
        </motion.button>
    ) : null;
};

export default ScrollToTop;
