import React from "react";
import { Helmet } from 'react-helmet-async';
import HeroSection from "../sections/about/HeroSection";
import TopNavigaionBar from "../components/TopNavigationBar";
import WhyChooseUs from "../sections/about/WhyChooseUs";
import Footer from "../components/Footer";
import OtherServices from "../sections/about/OtherServices";
import FloatingButtons from "../components/FloatingButtons";

export default function AboutUs() {
  return (
    <div>
      <Helmet>
        <title>
          Newline Limited - About Us | Timeless Design & Modern Elegance
        </title>
        <meta
          name="description"
          content="Discover Newline Limited's commitment to modern elegance and timeless design. Specializing in high-quality, custom furniture and interior solutions in Kenya."
        />
        <meta
          name="keywords"
          content="Newline Limited Kenya, modern interior design, custom furniture, high-quality materials, office furniture, home decor, kitchens, wardrobes, sustainable design, Nairobi furniture store"
        />
        <link rel="canonical" href="https://www.newlinelimited.com/about-us" />
        <meta
          property="og:title"
          content="Newline Limited - About Us | Timeless Design & Modern Elegance"
        />
        <meta
          property="og:description"
          content="At Newline Limited, we blend global quality with local craftsmanship to create furniture that transforms spaces and elevates lifestyles."
        />
        <meta
          property="og:url"
          content="https://www.newlinelimited.com/about-us"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="URL_TO_ABOUT_US_PAGE_IMAGE" />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigaionBar />
      <HeroSection />
      <WhyChooseUs />
      <OtherServices />
      <FloatingButtons/>
      <Footer backgroundColor="bg-gray-100" />
    </div>
  );
}
