import React from "react";
import Image1 from "../../../images/school/1.webp";
import Image2 from "../../../images/school/2.webp";
import Image3 from "../../../images/school/3.webp";

export default function Content() {
    const contentData = [
        {
            id: 1, // Make sure each id is unique
            title: "Revolutionizing Educational Environments",
            description:
                "Uncover the transformative power of Newline Education's innovative and adaptable learning spaces, where cutting-edge design meets the dynamic needs of modern education. Our solutions redefine the traditional classroom, blending flexibility, technology, and comfort to support diverse learning styles and teaching methodologies. Elevate your educational institution with our customized, forward-thinking designs that cater to students and educators alike.",
            imageSrc: Image1,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Adaptable Classroom Designs",
            description:
                "Discover Newline Education's revolution in classroom environments, where versatile furniture and smart technology integration create a fluid space for interactive and engaging learning experiences. Our designs emphasize spacious layouts, ergonomic seating, and robust technological infrastructures to accommodate a variety of teaching styles and learning activities.",
            imageSrc: Image2,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Innovative Common Areas",
            description:
                "Explore the heart of communal learning with Newline's creatively designed common areas. These spaces foster collaboration, relaxation, and inspiration among students, featuring modular seating arrangements, vibrant colors, and multifunctional zones equipped for both group projects and individual study.",
            imageSrc: Image3,
        },
    ];

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            {contentData.map((item, index) => (
                <div
                    key={item.id}
                    className={` ${index % 2 === 0 ? "" : "md:grid-rows-2"}`}
                >
                    {index % 2 === 0 ? (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
