import React from "react";
// import Hero from "../../images/homepage/heroImage.webp";
import MouseScroll from "../../components/mouse";
import ShuffleGallery from "./ShuffleGallery";

export default function HeroSection() {
    return (
        <div className="relative flex flex-col place h-screen sm:h-[100vh]">
            {/* <img
        src={Hero}
        alt="Decorative Background"
        className="object-cover object-center h-screen w-full absolute top-0 left-0 bottom-0 -z-10"
      /> */}
            <video
                src="/video/NewlineLimited.mp4"
                className="object-cover object-center h-screen w-full absolute top-0 left-0 bottom-0 -z-10"
                autoPlay
                muted
                loop
            />
            <div className="object-cover object-center bg-black opacity-40 h-screen w-full absolute top-0 left-0 bottom-0 -z-10"></div>
            <div className="mx-16 sm:mx-4 mt-44 sm:mt-24">
                <h1 className="font-bold text-4xl w-2/5 sm:w-4/5 sm:text-2xl text-white">
                    Global Quality, Local Craftsmanship
                </h1>
                <p className="my-2 text-lg sm:text-base text-white">
                    Newline Limited: Furnishing Futures, Worldwide.
                </p>
                <div className="sm:w-full sm:flex sm:justify-center sm:items-center sm:my-16">
                    <ShuffleGallery />
                </div>
            </div>
            <MouseScroll />
        </div>
    );
}
