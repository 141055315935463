import React from "react";

export default function DropdownSelect({
    id,
    label,
    value,
    onChange,
    options,
    disabled,
}) {
    return (
        <div>
            <label htmlFor={id}>{label}</label>
            <select
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                disabled={disabled}
            >
                <option value="">Select {label}</option>
                {options && Array.isArray(options) && options.map(option => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
}
