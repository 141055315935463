import React from "react";
import WhatsAppButton from "./WhatsApp";
import ScrollToTop from "./ScrollToTop";

export default function FloatingButtons() {
    return (
        <div className="grid gap-y-6 fixed right-4 bottom-4 place-items-center justify-items-center">
            <WhatsAppButton phoneNumber={+254115795285} />
            <ScrollToTop />
        </div>
    );
}
