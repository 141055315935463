import React from 'react'
import { NavLink} from "react-router-dom";

export default function Construction() {
  return (
    <div className='h-screen overflow-hidden grid grid-cols-2 sm:flex sm:flex-col-reverse'>
      <div className="bg-construction bg-center bg-no-repeat bg-cover h-screen sm:h-[60vh] w-full">
      </div>
      <div className='p-16 my-16 sm:my-4'>
        <h1 className='mb-16 text-3xl'>This Page is currently under construction </h1>
        <NavLink to="/" className="border border-black p-4">
            Homepage
        </NavLink>
      </div>
    </div>
  )
}
