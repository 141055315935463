import React from "react";
import TopNavigationBar from "../../../components/TopNavigationBar";
import productData from "../../../data/office/table/CoffeeTable.json"; // Ensure this path is correct
import Footer from "../../../components/Footer";
import HeroSection from "./HeroSection";
import ProductItem from "../../../components/ProductItem";
import Image from "../../../images/homepage/king_side_coffee _tables.webp";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function CoffeeTable() {
  return (
    <div>
      <Helmet>
        <title>Coffee Tables - Office Furniture | Newline Limited</title>
        <meta
          name="description"
          content="Discover the perfect coffee table for your office space with Newline Limited's diverse collection. From modern minimalistic to classic elegance, find a coffee table that adds sophistication and functionality to your environment."
        />
        <meta
          name="keywords"
          content="office coffee table, modern coffee table, elegant furniture, office furniture Kenya, Newline Limited, sophisticated office decor, office lounge furniture, premium coffee tables"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/coffee-tables"
        />
        <meta
          property="og:title"
          content="Coffee Tables - Office Furniture | Newline Limited"
        />
        <meta
          property="og:description"
          content="Explore Newline Limited's selection of coffee tables designed for functionality and style, perfect for any office space."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/coffee-tables"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="URL_TO_FEATURED_COFFEE_TABLE_IMAGE"
        />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigationBar />
      <HeroSection
        title="Coffee Table"
        imageURL={Image}
        imageHeight="w-3/5"
        description="Looking for a coffee table that stands out in both functionality and style? Our collection features a variety of designs to complement your space, from modern minimalistic to classic elegance. Whether it's for a waiting area, lounge, or personal office, we're here to help you find the perfect coffee table that not only serves as a central piece for gatherings but also adds a touch of sophistication to your environment. Let us assist you in choosing a coffee table that enhances your space's aesthetics while meeting your practical needs."
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 m-16 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer />
    </div>
  );
}
