import React from "react";

export default function Location() {
  return (
    <div className="px-16 sm:px-2 sm:grid flex  gap-6 sm:gap-0 sm:gap-y-4">
      <div className="w-full p-4">
        <h1 className="text-2xl sm:text-xl font-bold my-8">Office Location</h1>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8154273016717!2d36.81590547436117!3d-1.2846874356231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d9ddbccdb5%3A0xcf3cbfa2760b27cb!2sNEWLINE%20Office%20%26%20kitchen%20Furnitures!5e0!3m2!1sen!2ske!4v1707692497046!5m2!1sen!2ske"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full h-[80vh] sm:h-[40vh] rounded-lg"
          title="Office Location"
        ></iframe>
      </div>
      <div className="w-full p-4">
        <h1 className="text-2xl sm:text-xl font-bold my-8">Factory Location</h1>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7225831269056!2d36.88175267436141!3d-1.3428398356892641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0df42aa0fbf7%3A0x145315bee4a676cb!2sNewline%20Limited%20Factory!5e0!3m2!1sen!2ske!4v1708083046760!5m2!1sen!2ske"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="w-full h-[80vh] sm:h-[40vh] rounded-lg"
          title="Factory Location"
        ></iframe>
      </div>
    </div>
  );
}
