import React, { useEffect } from "react"; // Import useEffect
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Homepage from "../src/webpages/Homepage";
import AboutUs from "./webpages/AboutUs";
import ContactUs from "./webpages/ContactUs";
import HighBackChairs from "./webpages/products/office/HighBackChairs";
import MidBackChairs from "./webpages/products/office/MidBackChairs";
import VisitorsChair from "./webpages/products/office/VisitorsChair";
import CoffeeTable from "./webpages/products/office/CoffeeTable";
import ReceptionChairs from "./webpages/products/office/ReceptionChairs";
import OfficeDesks from "./webpages/products/office/OfficeDesks";
import "./App.css";
import "boxicons";
import Construction from "./components/Construction";
import { useAuth } from "./webpages/auth/AuthProvider";
import Layout from "./components/Layout";
import Dashboard from "./webpages/admin/Dashboard";
import Login from "./webpages/auth/Login";
import Signup from "./webpages/auth/Signup";
import RequestPasswordReset from "./components/RequestPassword";
import ResetPassword from "./components/ResetPassword";
import AddProducts from "./webpages/admin/Products/AddProducts";
import { motion } from "framer-motion";
import OfficeWorkStation from "./webpages/products/office/OfficeWorkstation";
import OfficeConferenceTables from "./webpages/products/office/ConferenceTables";
import OfficeReceptionDesk from "./webpages/products/office/ReceptionDesk";
import OfficeManagerialDesk from "./webpages/products/office/ManagerialDesks";
import OfficeCabinet from "./webpages/products/office/Cabinets";
import HomeBedroomBeds from "./webpages/products/home/BedroomBeds";
import HomeBedSideCabinets from "./webpages/products/home/BedSideCabinets";
import HomeDiningTables from "./webpages/products/home/DiningTables";
import HomeCoffeeTable from "./webpages/products/home/CoffeeTables";
import HomeOttomans from "./webpages/products/home/Ottomans";
import HomeSofas from "./webpages/products/home/Sofas";
import HomeTVStands from "./webpages/products/home/TvStands";
import Pillows from "./webpages/products/home/Pillows";
import BookShelves from "./webpages/products/home/BookShelves";
import Kitchens from "./webpages/products/kitchen/Kitchens";
import School from "./webpages/products/schools/School";
import Commercial from "./webpages/products/commercial/Commercial";
import Wardrobes from "./webpages/products/wardrobes/Wardrobes";

function ScrollToTop() {
    const { pathname } = useLocation(); // Use useLocation hook to get the current pathname

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [pathname]); // This effect runs every time the pathname changes

    return null; // This component does not render anything
}
function ProtectedRoute({ children }) {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? children : <Navigate to="/login" />;
}

const pageTransitionVariants = {
    hidden: { opacity: 0, x: -200 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 200 },
};

const AnimatedRoute = ({ children }) => (
    <motion.div
        variants={pageTransitionVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ type: "linear" }}
    >
        {children}
    </motion.div>
);
function App() {
    return (
        <div className="font-montserrat">
            <ScrollToTop />{" "}
            {/* Include the ScrollToTop component inside your Router */}
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route
                    path="/office-furniture/chairs/high-back-chairs"
                    element={<HighBackChairs />}
                />
                <Route
                    path="/office-furniture/chairs/mid-back-chairs"
                    element={<MidBackChairs />}
                />
                <Route
                    path="/office-furniture/chairs/visitor's-chairs"
                    element={<VisitorsChair />}
                />
                <Route
                    path="/office-furniture/coffee-tables"
                    element={<CoffeeTable />}
                />
                <Route
                    path="/office-furniture/chairs/reception-chairs"
                    element={<ReceptionChairs />}
                />
                <Route
                    path="/office-furniture/desks/office-desks"
                    element={<OfficeDesks />}
                />
                 <Route
                    path="/office-furniture/desks/workstations"
                    element={<OfficeWorkStation />}
                />
                 <Route
                    path="/office-furniture/desks/conference-tables"
                    element={<OfficeConferenceTables />}
                />
                 <Route
                    path="/office-furniture/desks/reception-desks"
                    element={<OfficeReceptionDesk />}
                />
                <Route
                    path="/office-furniture/desks/managerial-executive-desks"
                    element={<OfficeManagerialDesk />}
                />
                <Route
                    path="/office-furniture/cabinets"
                    element={<OfficeCabinet/>}
                />
                <Route
                    path="/home-furniture/bedroom/beds"
                    element={<HomeBedroomBeds/>}
                />
                <Route
                    path="/home-furniture/bedroom/bedside-cabinets"
                    element={<HomeBedSideCabinets/>}
                />
                <Route
                    path="/home-furniture/dining-room/dining-tables"
                    element={<HomeDiningTables/>}
                />
                <Route
                    path="/home-furniture/living-room/coffee-tables"
                    element={<HomeCoffeeTable/>}
                />
                <Route
                    path="/home-furniture/living-room/ottomans"
                    element={<HomeOttomans/>}
                />
                 <Route
                    path="/home-furniture/living-room/sofas"
                    element={<HomeSofas/>}
                />
                 <Route
                    path="/home-furniture/living-room/tv-stands"
                    element={<HomeTVStands/>}
                />
                  <Route
                    path="/home-furniture/living-room/pillows"
                    element={<Pillows/>}
                />
                 <Route
                    path="/home-furniture/study-room/book-shelves"
                    element={<BookShelves/>}
                />
                <Route
                    path="/kitchen-&-wardrobes/kitchen-cabinets"
                    element={<Kitchens/>}
                />
                <Route
                    path="/commercial-interiors"
                    element={<Commercial/>}
                />
                <Route
                    path="/kitchen-&-wardrobe/wardrobes"
                    element={<Wardrobes/>}
                />
                <Route
                    path="/school-&-university"
                    element={<School/>}
                />
                <Route path="*" element={<Construction />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                    path="/request-password-reset"
                    element={<RequestPasswordReset />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                    path="/dashboard/*"
                    element={
                        <Layout>
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        </Layout>
                    }
                />
                <Route
                    path="/products/add-products"
                    element={
                        <AnimatedRoute>
                            <Layout>
                                <ProtectedRoute>
                                    <AddProducts />
                                </ProtectedRoute>
                            </Layout>
                        </AnimatedRoute>
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
