import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to import axios
import LoadingSpinner from "../../../components/LoadingSpinner";

export default function WardrobesGallery() {
    const [wardrobesGallery, setWardrobesGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImageURL = "Image Not Loaded"; // Replace with your actual placeholder image URL
    useEffect(() => {
        const fetchWardrobesGallery = async () => {
            setIsLoading(true);
            try {
                // Assuming HP's brand_id is "2" (replace "2" with the actual brand_id of HP)
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products?category_id=3&subcategory_id=36`
                );
                const processedWardrobesGallery = response.data.map(
                    (wardrobesGallery) => {
                        const mainImageURL =
                            wardrobesGallery.images &&
                            wardrobesGallery.images.length > 0
                                ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${wardrobesGallery.images[0].url}`
                                : placeholderImageURL;
                        return { ...wardrobesGallery, mainImageURL };
                    }
                );

                setWardrobesGallery(processedWardrobesGallery);
                console.log(processedWardrobesGallery);
            } catch (error) {
                console.error("Failed to fetch office workstations:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchWardrobesGallery();
    }, []);

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            <h1 className="text-3xl sm:text-xl font-bold text-center">Explore Our Wardrobe Gallery</h1>
            <div className="grid grid-cols-3 sm:grid-cols-1">
                {isLoading ? (
                    <div>
                        <LoadingSpinner />
                    </div>
                ) : (
                    wardrobesGallery.map((product) => (
                        <div key={product.id} className="p-4 sm:p-2">
                          <img src={product.mainImageURL} alt={product.title} />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
