import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import WebLogo from "../images/homepage/logo.png";
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
    FaTiktok,
    FaUser,
    FaFileAlt,
} from "react-icons/fa";

import { GoSearch } from "react-icons/go";

import { FaAnglesRight } from "react-icons/fa6";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import SideMenu from "./SideMenu";

export default function TopNavigationBar({ textColor }) {
    const [showOfficeSubMenu, setShowOfficeSubMenu] = useState(false);
    const [showLivingRoomSubMenu, setShowLivingRoomSubMenu] = useState(false);
    const [showHomeSubMenu, setShowHomeSubMenu] = useState(false);
    const [showOfficeChairsSubMenu, setShowOfficeChairsSubMenu] =
        useState(false);
    const [showOfficeDeskSubMenu, setShowOfficeDeskSubMenu] = useState(false);
    const [showStudyRoomSubMenu, setShowStudyRoomSubMenu] = useState(false);
    const [showVisaSubMenu, setShowVisaSubMenu] = useState(false);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const location = useLocation(); // Get the current location

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Define the style variants
    const navbarVariants = {
        scrolled: {
            backgroundColor: "#ffffff",
            color: "#000000",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
            transition: {
                type: "tween",
                duration: 0.3,
            },
        },
        notScrolled: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            color: "#ffffff",
            transition: {
                type: "tween",
                duration: 0.3,
            },
        },
    };

    const submenuVariants = {
        initial: {
            opacity: 0,
            y: -10,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.1,
            },
        },
        exit: {
            opacity: 0,
            y: -10,
            transition: {
                when: "afterChildren",
            },
        },
    };

    const itemVariants = {
        initial: {
            opacity: 0,
            y: -10,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
    };

    const submenuSlideVariants = {
        hidden: {
            x: 0, // Start off-screen to the left
            opacity: 0,
        },
        visible: {
            x: "20%",
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                duration: 0.5,
            },
        },
    };

    // Define your link color based on the isScrolled state
    const linkColor = isScrolled ? "text-black" : "text-white";

    return (
        <div>
            {/* Mobile Menu */}
            <button
                onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
                className="sm:flex sm:justify-center sm:items-center hidden  sm:fixed top-5 left-5 z-50 bg-white shadow-md shadow-gray-400 rounded-full p-2 h-12 w-12"
            >
                <HiOutlineMenuAlt2 size={28} />
            </button>
            {/* SideMenu Component */}
            <SideMenu
                isOpen={isSideMenuOpen}
                onClose={() => setIsSideMenuOpen(false)}
            />
            <motion.div
                variants={navbarVariants}
                animate={isScrolled ? "scrolled" : "notScrolled"}
                className="fixed top-0 right-0 left-0 z-40"
            >
                <div className="grid justify-center place-items-center sm:hidden my-6">
                    {location.pathname === "/" &&
                        (!isScrolled ? (
                            <div className="flex justify-between items-center w-[90vw]">
                                <div className="flex gap-4">
                                    <div className="flex gap-4">
                                        <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                            <NavLink
                                                to="https://www.facebook.com/newlineke?mibextid=ZbWKwL"
                                                target="_blank"
                                            >
                                                <FaFacebookF color="white" />
                                            </NavLink>
                                        </div>
                                        <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                            <NavLink
                                                to="https://x.com/ltd_newline?t=zuHcIDKUo8LO44i_QIUHvg&s=09"
                                                target="_blank"
                                            >
                                                <FaTwitter color="white" />
                                            </NavLink>
                                        </div>
                                        <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
                                            <NavLink
                                                to="https://www.instagram.com/newline_ltd?igsh=MTdreTgzcGRhM2RsbA=="
                                                target="_blank"
                                            >
                                                <FaInstagram color="white" />
                                            </NavLink>
                                        </div>
                                        <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
                                            <NavLink
                                                to="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
                                                target="_blank"
                                            >
                                                <FaLinkedinIn color="white" />
                                            </NavLink>
                                        </div>
                                        <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
                                            <NavLink
                                                to="https://www.tiktok.com/@newlinelimited?_t=8k2LuSsMpV8&_r=1"
                                                target="_blank"
                                            >
                                                <FaTiktok color="white" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <NavLink to="/" className="hover:text-dark-red">
                                    <img
                                        src={WebLogo}
                                        alt="Web Logo"
                                        className="w-3/5 mb-3"
                                    />
                                </NavLink>
                                <div className="flex gap-4">
                                    <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                        <NavLink to="/">
                                            <GoSearch />
                                        </NavLink>
                                    </div>
                                    <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                        <NavLink to="/login">
                                            <FaUser />
                                        </NavLink>
                                    </div>
                                    <div className="rounded-full h-10 w-40 border border-light-red flex justify-center items-center p-2 bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                        <a
                                            href="https://newline.co.ke/NEWLINE%20OFFICE%20CATALOGUE.pdf" // URL of the PDF
                                            target="_blank" // Ensures it opens in a new tab
                                            rel="noopener noreferrer" // Security reasons
                                            className="flex items-center justify-center h-full w-full text-white"
                                        >
                                            <div className="bg-light-red p-2 rounded-full">
                                                <FaFileAlt className="text-white" />
                                            </div>
                                            <span className="ml-2 text-xs text-white">
                                                Office Catalogue
                                            </span>{" "}
                                            {/* Updated text */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ) : null)}

                    {/* Navigation Links */}
                    <div className="flex gap-6 px-4 uppercase items-center">
                        {(isScrolled || location.pathname !== "/") && (
                            <NavLink to="/">
                                <img
                                    src={WebLogo}
                                    alt="Web Logo"
                                    className="w-36"
                                />
                            </NavLink>
                        )}
                        <div
                            className={`flex gap-4 px-4 uppercase items-center ${linkColor} text-xs`}
                        >
                            <div
                                onMouseEnter={() => setShowOfficeSubMenu(true)}
                                onMouseLeave={() => setShowOfficeSubMenu(false)}
                                className="relative"
                            >
                                <NavLink className="hover:text-dark-red">
                                    Office Furniture
                                </NavLink>
                                {showOfficeSubMenu && (
                                    <motion.div
                                        className="absolute top-0 mt-4 left-0 w-max h-max flex justify-center items-center bg-white text-black rounded shadow-lg"
                                        variants={submenuVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                    >
                                        <div className="grid w-max m-2">
                                            <div
                                                onMouseEnter={() =>
                                                    setShowOfficeChairsSubMenu(
                                                        true
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setShowOfficeChairsSubMenu(
                                                        false
                                                    )
                                                }
                                                className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                            >
                                                <p>Chairs</p>
                                                <FaAnglesRight />
                                                <AnimatePresence>
                                                    {showOfficeChairsSubMenu && (
                                                        <AnimatePresence>
                                                            <motion.div
                                                                className="absolute text-black left-full -ml-10 top-0 w-max bg-white shadow-lg rounded-lg py-4"
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                                variants={
                                                                    submenuSlideVariants
                                                                }
                                                            >
                                                                <motion.ul
                                                                    className="list-none grid gap-y-8 p-4"
                                                                    variants={
                                                                        submenuVariants
                                                                    } // Parent variants with staggerChildren
                                                                    initial="initial"
                                                                    animate="animate"
                                                                    exit="exit"
                                                                >
                                                                    {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/chairs/high-back-chairs"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            High
                                                                            Back
                                                                            Chairs
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/chairs/mid-back-chairs"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Mid
                                                                            Back
                                                                            Chairs
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/chairs/visitor's-chairs"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Visitor's
                                                                            Chairs
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/chairs/reception-chairs"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Reception
                                                                            Chairs
                                                                        </NavLink>
                                                                    </motion.li>
                                                                </motion.ul>
                                                            </motion.div>
                                                        </AnimatePresence>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                            <div
                                                onMouseEnter={() =>
                                                    setShowOfficeDeskSubMenu(
                                                        true
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setShowOfficeDeskSubMenu(
                                                        false
                                                    )
                                                }
                                                className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                            >
                                                <p>Desks</p>
                                                <FaAnglesRight />
                                                <AnimatePresence>
                                                    {showOfficeDeskSubMenu && (
                                                        <AnimatePresence>
                                                            <motion.div
                                                                className="absolute text-black left-full -ml-32 top-0 w-max bg-white shadow-lg rounded-lg py-4"
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                                variants={
                                                                    submenuSlideVariants
                                                                }
                                                            >
                                                                <motion.ul
                                                                    className="list-none grid grid-cols-2 gap-y-8 p-4"
                                                                    variants={
                                                                        submenuVariants
                                                                    } // Parent variants with staggerChildren
                                                                    initial="initial"
                                                                    animate="animate"
                                                                    exit="exit"
                                                                >
                                                                    {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/office-desks"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Office
                                                                            Desks
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/managerial-executive-desks"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Executive
                                                                            Desks
                                                                            /
                                                                            Managerial
                                                                            Desks
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/workstations"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Workstation
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/conference-tables"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Conferece
                                                                            Tables
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/reception-desks"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Reception
                                                                            Desks
                                                                        </NavLink>
                                                                    </motion.li>
                                                                </motion.ul>
                                                            </motion.div>
                                                        </AnimatePresence>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                            <motion.li
                                                variants={itemVariants}
                                                className="list-none"
                                            >
                                                <NavLink
                                                    to="/office-furniture/coffee-tables"
                                                    className="block px-4 rounded-lg hover:bg-red-500 hover:text-white py-4"
                                                >
                                                    Coffee Tables
                                                </NavLink>
                                            </motion.li>
                                            <motion.li
                                                variants={itemVariants}
                                                className="list-none"
                                            >
                                                <NavLink
                                                    to="/office-furniture/cabinets"
                                                    className="block px-4 rounded-lg hover:bg-red-500 hover:text-white py-4"
                                                >
                                                    Cabinets
                                                </NavLink>
                                            </motion.li>
                                        </div>
                                    </motion.div>
                                )}
                            </div>

                            <div
                                onMouseEnter={() => setShowHomeSubMenu(true)}
                                onMouseLeave={() => setShowHomeSubMenu(false)}
                                className="relative"
                            >
                                <NavLink className="hover:text-dark-red">
                                    Home Furniture
                                </NavLink>
                                {showHomeSubMenu && (
                                    <motion.div
                                        className="absolute top-0 mt-4 left-0 w-max h-max flex justify-center items-center bg-white text-black rounded shadow-lg"
                                        variants={submenuVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                    >
                                        <div className="grid w-max m-2">
                                            <div
                                                onMouseEnter={() =>
                                                    setShowOfficeChairsSubMenu(
                                                        true
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setShowOfficeChairsSubMenu(
                                                        false
                                                    )
                                                }
                                                className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                            >
                                                <p>Bed</p>
                                                <FaAnglesRight />
                                                <AnimatePresence>
                                                    {showOfficeChairsSubMenu && (
                                                        <AnimatePresence>
                                                            <motion.div
                                                                className="absolute text-black left-full -ml-10 top-0 w-max bg-white shadow-lg rounded-lg py-4"
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                                variants={
                                                                    submenuSlideVariants
                                                                }
                                                            >
                                                                <motion.ul
                                                                    className="list-none grid gap-y-8 p-4"
                                                                    variants={
                                                                        submenuVariants
                                                                    } // Parent variants with staggerChildren
                                                                    initial="initial"
                                                                    animate="animate"
                                                                    exit="exit"
                                                                >
                                                                    {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/home-furniture/bedroom/beds"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Beds
                                                                        </NavLink>
                                                                    </motion.li>
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/home-furniture/bedroom/bedside-cabinets"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Bed-Side
                                                                            Cabinets
                                                                        </NavLink>
                                                                    </motion.li>
                                                                </motion.ul>
                                                            </motion.div>
                                                        </AnimatePresence>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                            <div
                                                onMouseEnter={() =>
                                                    setShowOfficeDeskSubMenu(
                                                        true
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setShowOfficeDeskSubMenu(
                                                        false
                                                    )
                                                }
                                                className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                            >
                                                <p>Dining Room</p>
                                                <FaAnglesRight />
                                                <AnimatePresence>
                                                    {showOfficeDeskSubMenu && (
                                                        <AnimatePresence>
                                                            <motion.div
                                                                className="absolute text-black left-full -ml-9 top-0 w-max bg-white shadow-lg rounded-lg py-4"
                                                                initial="hidden"
                                                                animate="visible"
                                                                exit="hidden"
                                                                variants={
                                                                    submenuSlideVariants
                                                                }
                                                            >
                                                                <motion.ul
                                                                    className="list-none grid gap-y-8 p-4"
                                                                    variants={
                                                                        submenuVariants
                                                                    } // Parent variants with staggerChildren
                                                                    initial="initial"
                                                                    animate="animate"
                                                                    exit="exit"
                                                                >
                                                                    {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                    {/* <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/office-furniture/desks/office-desks"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Dining
                                                                            Chairs
                                                                        </NavLink>
                                                                    </motion.li> */}
                                                                    <motion.li
                                                                        variants={
                                                                            itemVariants
                                                                        }
                                                                    >
                                                                        <NavLink
                                                                            to="/home-furniture/dining-room/dining-tables"
                                                                            className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                        >
                                                                            Dining
                                                                            Tables
                                                                        </NavLink>
                                                                    </motion.li>
                                                                </motion.ul>
                                                            </motion.div>
                                                        </AnimatePresence>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                            <motion.li
                                                variants={itemVariants}
                                                className="list-none"
                                            >
                                                <div
                                                    onMouseEnter={() =>
                                                        setShowLivingRoomSubMenu(
                                                            true
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        setShowLivingRoomSubMenu(
                                                            false
                                                        )
                                                    }
                                                    className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                                >
                                                    <p>Living Room</p>
                                                    <FaAnglesRight />
                                                    <AnimatePresence>
                                                        {showLivingRoomSubMenu && (
                                                            <AnimatePresence>
                                                                <motion.div
                                                                    className="absolute text-black left-full -ml-16 top-0 w-max bg-white shadow-lg rounded-lg py-4"
                                                                    initial="hidden"
                                                                    animate="visible"
                                                                    exit="hidden"
                                                                    variants={
                                                                        submenuSlideVariants
                                                                    }
                                                                >
                                                                    <motion.ul
                                                                        className="list-none grid grid-cols-2 gap-y-8 p-4"
                                                                        variants={
                                                                            submenuVariants
                                                                        } // Parent variants with staggerChildren
                                                                        initial="initial"
                                                                        animate="animate"
                                                                        exit="exit"
                                                                    >
                                                                        {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/living-room/coffee-tables"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                Coffee
                                                                                Tables
                                                                            </NavLink>
                                                                        </motion.li>
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/living-room/ottomans"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                Ottomans
                                                                            </NavLink>
                                                                        </motion.li>
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/living-room/sofas"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                Sofas
                                                                            </NavLink>
                                                                        </motion.li>
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/living-room/pillows"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                Pillows
                                                                            </NavLink>
                                                                        </motion.li>
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/living-room/tv-stands"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                TV
                                                                                Stands
                                                                            </NavLink>
                                                                        </motion.li>
                                                                    </motion.ul>
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        )}
                                                    </AnimatePresence>
                                                </div>
                                            </motion.li>
                                            <motion.li
                                                variants={itemVariants}
                                                className="list-none"
                                            >
                                                <div
                                                    onMouseEnter={() =>
                                                        setShowStudyRoomSubMenu(
                                                            true
                                                        )
                                                    }
                                                    onMouseLeave={() =>
                                                        setShowStudyRoomSubMenu(
                                                            false
                                                        )
                                                    }
                                                    className="prelative cursor-pointer flex justify-between items-center p-4 rounded-lg hover:bg-red-500 hover:text-white"
                                                >
                                                    <p>Study Room</p>
                                                    <FaAnglesRight />
                                                    <AnimatePresence>
                                                        {showStudyRoomSubMenu && (
                                                            <AnimatePresence>
                                                                <motion.div
                                                                    className="absolute text-black left-full -ml-9 top-32 w-max bg-white shadow-lg rounded-lg py-4"
                                                                    initial="hidden"
                                                                    animate="visible"
                                                                    exit="hidden"
                                                                    variants={
                                                                        submenuSlideVariants
                                                                    }
                                                                >
                                                                    <motion.ul
                                                                        className="list-none grid gap-y-8 p-4"
                                                                        variants={
                                                                            submenuVariants
                                                                        } // Parent variants with staggerChildren
                                                                        initial="initial"
                                                                        animate="animate"
                                                                        exit="exit"
                                                                    >
                                                                        {/* Wrap each NavLink with motion.li to ensure they are animated as part of the stagger effect */}
                                                                        <motion.li
                                                                            variants={
                                                                                itemVariants
                                                                            }
                                                                        >
                                                                            <NavLink
                                                                                to="/home-furniture/study-room/book-shelves"
                                                                                className="hover:bg-red-500 hover:text-white p-4 rounded-md"
                                                                            >
                                                                                Book
                                                                                Shelves
                                                                            </NavLink>
                                                                        </motion.li>
                                                                    </motion.ul>
                                                                </motion.div>
                                                            </AnimatePresence>
                                                        )}
                                                    </AnimatePresence>
                                                </div>
                                            </motion.li>
                                        </div>
                                    </motion.div>
                                )}
                            </div>

                            <div
                                onMouseEnter={() => setShowVisaSubMenu(true)}
                                onMouseLeave={() => setShowVisaSubMenu(false)}
                                className="relative"
                            >
                                <NavLink className="hover:text-dark-red">
                                    Kitchens & Wardrobes
                                </NavLink>
                                {showVisaSubMenu && (
                                    <motion.div
                                        className="absolute top-3 left-0 w-max bg-white text-black mt-1 p-3 rounded shadow-lg"
                                        variants={submenuVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                    >
                                        <motion.li
                                            variants={itemVariants}
                                            className="list-none"
                                        >
                                            <NavLink
                                                to="/kitchen-&-wardrobes/kitchen-cabinets"
                                                className="block px-4 rounded-lg hover:bg-red-500 hover:text-white py-4"
                                            >
                                                Kitchen
                                            </NavLink>
                                        </motion.li>
                                        <hr className="col-span-3 my-3" />
                                        <motion.li
                                            variants={itemVariants}
                                            className="list-none"
                                        >
                                            <NavLink
                                                to="/kitchen-&-wardrobe/wardrobes"
                                                className="block px-4 rounded-lg hover:bg-red-500 hover:text-white py-4"
                                            >
                                                Wardrobe
                                            </NavLink>
                                        </motion.li>
                                    </motion.div>
                                )}
                            </div>

                            <NavLink
                                to="/commercial-interiors"
                                className="hover:text-dark-red"
                            >
                                Commercial Interiors
                            </NavLink>
                            <NavLink
                                to="/school-&-university"
                                className="hover:text-dark-red"
                            >
                                School & University
                            </NavLink>
                            <NavLink
                                to="/"
                                className="hover:text-dark-red"
                            >
                                Hospitality
                            </NavLink>
                            <NavLink
                                to="/about-us"
                                className="hover:text-dark-red"
                            >
                                About Us
                            </NavLink>
                            <NavLink
                                to="/contact-us"
                                className="bg-dark-red p-4 text-white rounded-xl hover:bg-light-red transition ease-in"
                            >
                                Contact Us
                            </NavLink>
                            {isScrolled && (
                                <div className="rounded-full h-10 w-10 border border-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
                                    <a
                                        href="https://newline.co.ke/NEWLINE%20OFFICE%20CATALOGUE.pdf" // URL of the PDF
                                        target="_blank" // Ensures it opens in a new tab
                                        rel="noopener noreferrer" // Security reasons
                                        className="flex items-center justify-center h-full w-full text-white"
                                    >
                                        <div className="bg-light-red p-2 rounded-full">
                                            <FaFileAlt className="text-white" />
                                        </div>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}
