import React from "react";

export default function HeroSection() {
    return (
        <div className="relative flex flex-col place h-screen sm:h-[100vh]">
            <div className="bg-school bg-no-repeat bg-cover bg-center absolute top-0 left-0 h-screen">
                <div className="absolute h-full w-full bg-black opacity-65 flex justify-center place-items-center"></div>
                <div className="w-full h-full flex justify-center items-center">
                    <div className="text-white  z-30 relative  text-center">
                        {" "}
                        <h1 className="font-bold text-3xl sm:text-lg my-6">
                            UNLEASH THE ART OF EDUCATIONAL SPACES DESIGN
                        </h1>
                        <p className="px-16 sm:p-4">
                            At Newline Education, we understand that learning
                            spaces are more than just rooms; they are the
                            cradles of inspiration, knowledge, and innovation.
                            Our mission is to redefine educational environments,
                            from primary schools to universities, transforming
                            them into vibrant, functional, and inspiring spaces.
                            Explore our diverse range of design solutions and
                            discover the perfect setting for every learning
                            journey. Let us help you "UNLEASH THE ART OF
                            EDUCATIONAL SPACES DESIGN" and elevate your
                            educational facility into a masterpiece of
                            creativity, functionality, and sheer elegance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
