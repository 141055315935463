import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  AiOutlineHome,
  AiOutlineSetting,
  AiOutlineFileText,
  AiOutlineBarChart,
  AiOutlineInbox,
  AiOutlineMenu,
  AiOutlineSearch,
} from "react-icons/ai";
import { FaBagShopping } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import AvatarProfile from "./AvatarProfile";
import { NavLink } from "react-router-dom";
import axios from "axios";

const submenuContainerVariants = {
  open: {
    opacity: 1,
    height: "auto", // Use a specific height if you know the submenu's height or keep it auto.
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  closed: {
    opacity: 0,
    height: 0, // Transitioning back to height 0 for the slide-up effect
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
      staggerDirection: -1,
      duration: 0.2,
      ease: "easeInOut",
    },
  },
};

const submenuItemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: -20,
    opacity: 0,
  },
};

const topMenuVariants = {
  expanded: { width: "calc(100%)", opacity: 1, transition: { duration: 0.3 } },
  collapsed: { width: "calc(100%)", opacity: 1, transition: { duration: 0.3 } },
};
const Layout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userDetails, setUserDetails] = useState({
    fname: "",
    lname: "",
    email: "",
  });
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const handleToggleMenu = (menuName) => {
    setExpandedMenu(expandedMenu === menuName ? null : menuName);
  };

  useEffect(() => {
    axios.defaults.withCredentials = true; // If needed for cookies with CORS

    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LARAVEL_APP_API_URL}/user/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        // Axios automatically parses the JSON response, so no need to call .json()
        const { data } = response;
        setUserDetails({
          fname: data.fname, // Adjust these keys based on your actual response structure
          lname: data.lname,
          email: data.email,
        });
      } catch (error) {
        console.error(
          "Error fetching user details:",
          error.response ? error.response.data.message : error.message
        );
      }
    };

    fetchUserDetails();
  }, []);

  const navItems = [
    { Icon: AiOutlineHome, text: "Home", to: "/dashboard" },
    { Icon: AiOutlineBarChart, text: "Goals", to: "/goals" },
    { Icon: AiOutlineInbox, text: "Private Tasks", to: "/private-tasks" },
    { Icon: AiOutlineFileText, text: "Analytics", to: "/analytics" },
  ];
  const myShopSubMenu = [
    { text: "Add Products", to: "/products/add-products" },
    { text: "Create Tags", to: "/products/create-tags" },
  ];

  return (
    <div className="flex flex-col h-screen">
      {/* Top menu */}
      <motion.div
        className="fixed top-0 left-0 right-0 flex items-center bg-light-red text-white p-6 shadow-md z-10"
        initial="collapsed"
        animate={isCollapsed ? "collapsed" : "expanded"}
        variants={topMenuVariants}
      >
        <AiOutlineMenu
          className="h-6 w-6 mr-4 cursor-pointer"
          onClick={toggleSidebar}
        />
        <span className="font-semibold flex-1">Dashboard</span>
        <div className="relative">
          <AiOutlineSearch className="absolute inset-y-0 left-0 w-8 h-8 my-auto pl-3 text-gray-500" />
          <input
            className="bg-white text-white pl-10 pr-3 py-1 rounded-full focus:outline-none"
            type="text"
            placeholder="Search..."
          />
        </div>
        <AiOutlineSetting className="h-5 w-5 mx-6" />
        <div className="w-max-w">
          <div className="w-max-w">
            <AvatarProfile
              name={`${userDetails.fname} ${userDetails.lname}`}
              title={userDetails.email}
            />
          </div>
        </div>
      </motion.div>
      <div className="flex flex-1 pt-20 overflow-y-hidden">
        {/* Sidebar */}
        <motion.aside
          className={`bg-light-red text-white transition-all duration-300 ${
            isCollapsed ? "w-20" : "w-64"
          } p-2`}
          initial={false}
          animate={isCollapsed ? { width: "70px" } : { width: "200px" }}
        >
          <h2
            className={`text-2xl font-semibold pl-4 text-left mb-6 ${
              isCollapsed ? "hidden" : "block"
            }`}
          >
            Newline
          </h2>

          <nav className="grid gap-y-4">
            {navItems.map(({ Icon, text, to }, index) => (
              <React.Fragment key={text}>
                <NavLink
                  to={to}
                  className="flex items-center space-x-2 py-2.5 px-4 rounded transition duration-200 hover:bg-dark-red"
                  activeClassName="bg-dark-red"
                >
                  <Icon className="h-5 w-5" />
                  {!isCollapsed && <span>{text}</span>}
                </NavLink>
                {text === "Home" && (
                  <>
                    <motion.button
                      whileHover={{ scale: 1.0 }}
                      className="mt-2 flex items-center justify-between space-x-2 py-2.5 px-4 w-full text-white bg-transparent rounded transition duration-200 hover:bg-dark-red"
                      onClick={() => handleToggleMenu("My Shop")}
                    >
                      <div className="flex gap-x-2">
                        <FaBagShopping className="h-5 w-5" />
                        {!isCollapsed && <span>My Shop</span>}
                      </div>
                      <FaChevronRight className="h-3 w-3" />
                    </motion.button>
                    {expandedMenu === "My Shop" && (
                      <motion.div
                        variants={submenuContainerVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        className="pl-4 overflow-hidden"
                      >
                        {myShopSubMenu.map((item) => (
                          <motion.a
                            key={item.text}
                            variants={submenuItemVariants}
                            whileHover={{ x: 10 }}
                            href={item.to}
                            className="block py-2 px-4 rounded transition duration-200 hover:bg-dark-red text-white"
                          >
                            {item.text}
                          </motion.a>
                        ))}
                      </motion.div>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </nav>
        </motion.aside>
        {/* Main content area */}
        <div className="flex-1 bg-gray-100 p-10 overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
