import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // Determine initial authentication state based on the presence of the auth token
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authToken') !== null);

  useEffect(() => {
    // Update isAuthenticated if authToken changes, this handles cases where the token might be manually removed or added
    setIsAuthenticated(localStorage.getItem('authToken') !== null);
  }, []);

  const login = (token) => {
    localStorage.setItem('authToken', token); // Store token in local storage
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('authToken'); // Remove the stored token on logout
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
