import React from "react";
import ProductsCard from "./dashboard/ProductsCard";
import DetailedProductCard from "./dashboard/DetailedProductCard";
import { TbToolsKitchen } from "react-icons/tb";
import { IoBusiness, IoHome } from "react-icons/io5";
import { IoMdSchool } from "react-icons/io";
import { ImOffice } from "react-icons/im";
import { BiSolidHotel } from "react-icons/bi";
import Table from "../../components/Table";

export default function Dashboard() {
    return (
        <div>
            <h1 className="text-3xl font-semibold">Products</h1>
            <div className="grid grid-cols-4 gap-6 my-6">
                <ProductsCard
                    value={100}
                    type="Kitchen & Wardrobes"
                    icon={TbToolsKitchen}
                />
                <ProductsCard
                    value={25}
                    type="Commercial Interior"
                    icon={IoBusiness}
                />
                <ProductsCard
                    value={150}
                    type="School & University"
                    icon={IoMdSchool}
                />
                <ProductsCard
                    value={150}
                    type="Hospitality"
                    icon={BiSolidHotel}
                />
                <DetailedProductCard value={64} type="Office" icon={ImOffice} />
                <DetailedProductCard value={75} type="Home" icon={IoHome} />
            </div>
            <div>
                <Table />
            </div>
        </div>
    );
}
