import React from "react";
import productData from "../../../data/office/chairs/HighBackChairs.json"; // Ensure this path is correct
import TopNavigationBar from "../../../components/TopNavigationBar";
import ProductItem from "../../../components/ProductItem";
import Footer from "../../../components/Footer";
import HeroSection from "./HeroSection";
import Image from "../../../images/homepage/anchor_high_back.webp";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function HighBackChairs() {
  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>
          High Back Office Chairs | Ergonomic & Stylish | Newline Limited
        </title>
        <meta
          name="description"
          content="Discover our range of high back office chairs designed for comfort, ergonomics, and style at Newline Limited. Find the perfect chair that meets your office needs and enhances workspace aesthetics."
        />
        <meta
          name="keywords"
          content="high back chairs, ergonomic office chairs, stylish office chairs, office furniture Kenya, comfortable office seating, Newline Limited"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/chairs/high-back-chairs"
        />
        <meta
          property="og:title"
          content="High Back Office Chairs | Ergonomic & Stylish | Newline Limited"
        />
        <meta
          property="og:description"
          content="Explore Newline Limited's selection of high back office chairs for superior comfort and style, perfect for any professional setting."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/chairs/high-back-chairs"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="URL_TO_FEATURED_HIGH_BACK_CHAIR_IMAGE"
        />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigationBar />
      <HeroSection
        title="High Back Office Chairs"
        description="Have questions or specific needs for your office seating? Our team
      specializes in high back office chairs, designed for comfort,
      ergonomics, and style. We're here to provide personalized support,
      ensuring your office furniture meets your exact requirements. From
      ergonomic designs that support long hours at the desk to stylish
      options that enhance your workspace, our dedicated team is ready to
      assist you in selecting the perfect high back chair for your office."
        imageURL={Image}
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 mx-16 sm:my-0 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}
