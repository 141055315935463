import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "../../../components/LoadingSpinner";
import DropdownSelect from "../../../components/DropdownSelect";

export default function AddProducts() {
    const token = localStorage.getItem("authToken");
    const [inStockStatusId] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        category: "",
        subCategories: "",
        type: "",
        main: undefined,
    });

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // Set token in axios defaults if available
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        axios.defaults.withCredentials = true;

        // Fetch CSRF token once
        axios
            .get(`${process.env.REACT_APP_SANCTUM_CSRF_URL}`)
            .then(() => console.log("CSRF token set"))
            .catch((error) =>
                console.error("Error fetching CSRF token:", error)
            );

        // Fetch categories once on component mount
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_LARAVEL_APP_API_URL}/categories`)
            .then((response) => {
                const sortedCategories = response.data.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                setCategories(sortedCategories);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Failed to fetch categories", error);
                setIsLoading(false);
            });
    }, [token]); // Only re-run if token changes

    useEffect(() => {
        // Fetch subcategories or types based on selected category/subcategory
        const fetchSubCategoriesOrTypes = () => {
            if (selectedCategory) {
                setIsLoading(true);
                axios
                    .get(
                        `${process.env.REACT_APP_LARAVEL_APP_API_URL}/categories/${selectedCategory}`
                    )
                    .then((response) => {
                        setSubCategories(response.data.subcategories);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error("Failed to fetch subcategories", error);
                        setIsLoading(false);
                    });
            } else {
                setSubCategories([]);
            }

            if (selectedSubCategory) {
                setIsLoading(true);
                axios
                    .get(
                        `${process.env.REACT_APP_LARAVEL_APP_API_URL}/subcategories/${selectedSubCategory}/types`
                    )
                    .then((response) => {
                        setTypes(response.data);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error("Failed to fetch types", error);
                        setIsLoading(false);
                    });
            } else {
                setTypes([]);
                setSelectedType("");
            }
        };

        fetchSubCategoriesOrTypes();
    }, [selectedCategory, selectedSubCategory]);

    useEffect(() => {
        console.log("Category selected:", selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        console.log("Subcategory selected:", selectedSubCategory);
    }, [selectedSubCategory]);

    useEffect(() => {
        console.log("Type selected:", selectedType);
    }, [selectedType]);

    // If loading, show the spinner
    if (isLoading) {
        return <LoadingSpinner />;
    }

    const validateImages = () => {
        if (formData.main && formData.main.size > 2048 * 1024) {
            toast.error("Main image must be 2MB or less.");
            return false;
        }

        const oversizedThumbnails = formData.thumbnails?.filter(
            (file) => file.size > 2048 * 1024
        );
        if (oversizedThumbnails?.length > 0) {
            toast.error("All thumbnails must be 2MB or less.");
            return false;
        }

        return true;
    };

    const resetForm = () => {
        // Resetting form data
        setFormData({
            title: "",
            description: "",
            main: undefined, // Assuming you have a state for main image
            category: "",
            subCategories: "",
            type: "",
        });

        setErrors({}); // Clearing any errors
    };
    const handleSelectChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm() || !validateImages()) return;

        console.log("Form Data:", formData); // Debugging formData content
        // Update formData with selected IDs before submission
        const updatedFormData = {
            ...formData,
            category: selectedCategory, // Assuming you want the selectedCategory ID
            subCategories: selectedSubCategory, // Assuming you want the selectedSubCategory ID
            type: selectedType, // Assuming you want the selectedType ID
        };

        console.log("Form Data:", updatedFormData);

        setIsLoading(true); // Start loading before the operation begins

        try {
            // Prepare Image Data
            const imageFormData = new FormData();
            if (formData.main) {
                imageFormData.append("main", formData.main);
                console.log("Main Image Added"); // Debugging image data
            }

            formData.thumbnails?.forEach((file, index) => {
                imageFormData.append(`thumbnails[${index}]`, file);
                console.log(`Thumbnail ${index} Added`); // Debugging image data
            });

            if (
                imageFormData.has("main") ||
                (formData.thumbnails && formData.thumbnails.length > 0)
            ) {
                const productFormData = new FormData();
                productFormData.append("title", formData.title);
                productFormData.append("description", formData.description);
                productFormData.append("category_id", selectedCategory); // Assuming the backend expects category_id
                productFormData.append("subcategory_id", selectedSubCategory); // Adjust based on actual backend expectation
                // Check if selectedType is not empty before appending type_id to productFormData
                if (selectedType) {
                    productFormData.append("type_id", selectedType);
                }

                if (inStockStatusId !== null) {
                    productFormData.append("status_id", inStockStatusId);
                }

                // Submit Product Data
                const productResponse = await axios.post(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products`,
                    productFormData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );

                // Assuming product ID is returned
                const productId = productResponse.data.id;
                console.log("Product ID:", productId); // Debugging product ID

                // Upload Images for the Created Product
                await axios.post(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products/${productId}/images`,
                    imageFormData,
                    { headers: { "Content-Type": "multipart/form-data" } }
                );

                toast.success("Product and images added successfully!");
                // Enhanced logging to include categories, subcategories, and types explicitly
                console.log("Full Product Details:", {
                    formData, // This is your existing form data
                    categories: categories.find(
                        (cat) => cat.id === selectedCategory
                    ), // This finds the full category object that was selected
                    subCategories: subCategories.find(
                        (subCat) => subCat.id === selectedSubCategory
                    ), // This finds the full subcategory object that was selected
                    type: types.find((type) => type.id === selectedType), // This finds the full type object that was selected
                });
            } else {
                toast.error(
                    "No images prepared for upload or they don't meet the requirements."
                );
            }
        } catch (error) {
            console.error("Error during product or image upload:", error);
            toast.error(
                "Failed to add product or upload images. Please try again."
            );
        } finally {
            setIsLoading(false); // Stop loading regardless of success or failure
            resetForm();
        }
    };

    // Component rendering logic remains unchanged

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!formData.main) {
            newErrors.main = "Main Product Image is required.";
            toast.error("Main Product Image is required.");
            isValid = false;
        }
        if (!formData.title.trim()) {
            newErrors.title = "Title is required.";
            toast.error("Title is required.");
            isValid = false;
        }

        if (!formData.description.trim()) {
            newErrors.description = "Description is required.";
            toast.error("Description is required.");
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleMainImageChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > 2048 * 1024) {
                // 2048 KB * 1024 = 2 MB
                toast.error("Main image must be 2MB or less.");
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    main: "Main image must be 2MB or less.",
                }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, main: "" })); // Clear the error if the file size is acceptable
                setFormData({ ...formData, main: file });
            }
        }
    };

    return (
        <div>
            <h1 className="text-3xl font-semibold">Add Products</h1>
            <div className="mx-auto bg-white p-6 rounded-xl my-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    {/* Image Upload */}
                    <div className="grid grid-cols-7 gap-4 items-start h-[40vh]">
                        <div className="col-span-3 h-full border border-gray-300 flex justify-center items-center">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Upload Image (Max 2MB)
                                </label>
                                <input
                                    type="file"
                                    name="main"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                                    onChange={handleMainImageChange} // Use the new handler here
                                    required
                                />
                                {errors.main && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {errors.main}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-span-4">
                            <label className="block">
                                <span className="text-gray-700">
                                    Product Title
                                </span>
                                <input
                                    type="text"
                                    name="title" // Ensure the name matches the key in formData
                                    value={formData.title} // Bind input value to formData
                                    onChange={handleFormChange} // Use the generic handleChange method
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                                />
                                {errors.title && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {errors.title}
                                    </p>
                                )}
                            </label>
                        </div>
                    </div>
                    {/* Dropdowns */}
                    <div className="grid grid-cols-3 gap-4">
                        <DropdownSelect
                            id="category"
                            label="Category"
                            value={selectedCategory}
                            onChange={handleSelectChange(setSelectedCategory)}
                            options={categories} // Use categories here for the dropdown
                            disabled={false}
                        />

                        <DropdownSelect
                            id="subCategory"
                            label="Sub Category"
                            value={selectedSubCategory}
                            onChange={handleSelectChange(
                                setSelectedSubCategory
                            )}
                            options={subCategories} // This should ideally be a separate list
                            disabled={!selectedCategory}
                        />
                        <DropdownSelect
                            id="type"
                            label="Type"
                            value={selectedType}
                            onChange={handleSelectChange(setSelectedType)}
                            options={types}
                            disabled={!selectedSubCategory}
                        />
                    </div>

                    {/* Description */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleFormChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-sm"
                            rows="4"
                        ></textarea>
                        {errors.description && (
                            <p className="text-red-500 text-xs mt-1">
                                {errors.description}
                            </p>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-max-w flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-light-red hover:bg-dark-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Add Product
                    </button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
}
