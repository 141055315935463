import React from "react";

export default function HeroSection() {
    return (
        <div className="relative flex flex-col place h-screen sm:h-[100vh]">
            <div className="bg-kitchen bg-no-repeat bg-cover bg-center absolute top-0 left-0 h-screen">
                <div className="absolute h-full w-full bg-black opacity-65 flex justify-center place-items-center"></div>
                <div className="w-full h-full flex justify-center items-center">
                    <div className="text-white  z-30 relative  text-center">
                        {" "}
                        <h1 className="font-bold text-3xl sm:text-lg my-6">
                            UNLEASH THE ART OF KITCHEN DESIGN
                        </h1>
                        <p className="px-16 sm:p-4">
                            At Newline Limited, we don't just design kitchens;
                            we create the heart of your home—a place where
                            families gather, stories are told, and culinary
                            dreams come alive. Explore our diverse range of
                            kitchen designs and find the perfect match for your
                            home. Let us help you "UNLEASH THE ART OF KITCHEN
                            DESIGN" and transform your kitchen into a
                            masterpiece of form, function, and unparalleled
                            elegance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
