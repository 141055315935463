import React from "react";

export default function HeroSection() {
    return (
        <div className="relative flex flex-col place h-screen sm:h-[100vh]">
            <div className="bg-wardrobes bg-no-repeat bg-cover bg-center absolute top-0 left-0 h-screen">
                <div className="absolute h-full w-full bg-black opacity-65 flex justify-center place-items-center"></div>
                <div className="w-full h-full flex justify-center items-center">
                    <div className="text-white  z-30 relative  text-center">
                        {" "}
                        <h1 className="font-bold text-3xl sm:text-lg my-6">
                            UNLEASH THE ART OF WARDROBE DESIGN
                        </h1>
                        <p className="px-16 sm:p-4">
                            At Newline Wardrobes, we believe that a wardrobe is
                            more than just a piece of furniture; it's a
                            sanctuary for your personal style, a haven where
                            every garment and accessory finds its rightful
                            place. We are dedicated to crafting spaces that
                            celebrate your unique fashion sense while offering
                            practical storage solutions. Dive into our extensive
                            selection of wardrobe designs and discover the ideal
                            complement to your lifestyle and decor. Allow us to
                            help you "UNLEASH THE ART OF WARDROBE DESIGN" and
                            transform your storage space into a symphony of
                            organization, beauty, and unmatched elegance.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
