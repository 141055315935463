import React from "react";

export default function ProductsCard({ value, type, icon: Icon }) { // Destructure and rename icon to Icon
  return (
    <div className="rounded-xl p-6 h-max-h bg-white shadow-lg grid">
      <div className="flex justify-between items-center">
        <h1>{type}</h1>
        <div className="border border-black p-2 rounded-md">
          {Icon ? <Icon /> : null}
        </div>
      </div>
      <p className="text-4xl font-bold my-2">{value}</p>
    </div>
  );
}
