import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to import axios
import LoadingSpinner from "../../../components/LoadingSpinner";

export default function SchoolGallery() {
    const [schoolGallery, setSchoolGallery] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImageURL = "Image Not Loaded"; // Replace with your actual placeholder image URL
    useEffect(() => {
        const fetchSchoolGallery = async () => {
            setIsLoading(true);
            try {
                // Assuming HP's brand_id is "2" (replace "2" with the actual brand_id of HP)
                const response = await axios.get(
                    `${process.env.REACT_APP_LARAVEL_APP_API_URL}/products?category_id=5`
                );
                const processedSchoolGallery = response.data.map(
                    (schoolGallery) => {
                        const mainImageURL =
                            schoolGallery.images &&
                            schoolGallery.images.length > 0
                                ? `${process.env.REACT_APP_LARAVEL_APP_IMAGE_URL}/storage/${schoolGallery.images[0].url}`
                                : placeholderImageURL;
                        return { ...schoolGallery, mainImageURL };
                    }
                );

                setSchoolGallery(processedSchoolGallery);
                console.log(processedSchoolGallery);
            } catch (error) {
                console.error("Failed to fetch office workstations:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSchoolGallery();
    }, []);

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            <h1 className="text-3xl sm:text-xl font-bold text-center">Explore Our School & University Gallery</h1>
            <div className="grid grid-cols-3 sm:grid-cols-1">
                {isLoading ? (
                    <div>
                        <LoadingSpinner />
                    </div>
                ) : (
                    schoolGallery.map((product) => (
                        <div key={product.id} className="p-4 sm:p-2">
                          <img src={product.mainImageURL} alt={product.title} />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
