import React from "react";

export default function HeroSection({ title, description, imageURL, imageHeight }) {
  return (
    <div>
      <div className="bg-[#3b5d50] h-[85vh] sm:h-[90vh] w-full grid grid-cols-2 sm:flex sm:flex-col-reverse items-center justify-between px-16 sm:px-4 text-white">
        <div className="w-4/5 sm:w-full">
          <h1 className="font-bold text-3xl sm:text-lg">{title}</h1>
          <p className="text-sm my-4">{description}</p>
          <div className="flex gap-4 my-6">
            <button className="p-4 rounded-full w-1/4 sm:w-full bg-transparent border border-white font-bold">
              Explore
            </button>
          </div>
        </div>
        <div className="flex justify-end items-end">
          <img src={imageURL} alt={imageURL} className={`${imageHeight}`}/>
        </div>
      </div>
    </div>
  );
}
