import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import { IoArrowBackCircleSharp } from "react-icons/io5";

export default function Login() {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const { login } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let isValid = true;
        // Clear all existing toasts before showing new ones
        toast.dismiss();

        if (!formData.email.trim()) {
            toast.error("Email is required");
            isValid = false;
        }
        if (!formData.password) {
            toast.error("Password is required");
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return; // Stop submission if validation fails
        // Set isLoading to true at the start of data fetching
        setIsLoading(true);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_LARAVEL_APP_API_URL}/login`,
                formData
            );

            // Assuming the token is in response.data.token
            const token = response.data.token; // Adjust based on actual response structure
            localStorage.setItem("authToken", token); // Store the token in local storage

            toast.success("User logged in successfully!");
            login(token); // Pass the token to the login method
            navigate("/dashboard"); // Redirect to dashboard after successful login
        } catch (error) {
            if (error.response) {
                // Handle specific server errors here
                const errorMessage =
                    error.response.data.message ||
                    "Login failed. Please try again.";
                toast.error(errorMessage);
            } else {
                // For network errors or other issues not caught by the response
                toast.error("An unexpected error occurred. Please try again.");
            }
        } finally {
            setIsLoading(false); // Stop loading regardless of success or failure
        }
    };

    // Component JSX with minor adjustments for error handling
    return (
        <div className="h-screen overflow-hidden grid grid-cols-2 place-items-center justify-items-center sm:flex sm:flex-col-reverse">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="bg-login bg-center bg-no-repeat bg-cover h-screen sm:h-[60vh] w-full"></div>
                    <div className="absolute top-0 left-0 m-10 flex justify-center items-center">
                        <NavLink to="/" className="text-white">
                            <IoArrowBackCircleSharp className="text-6xl" />
                        </NavLink>
                    </div>
                    <div className="w-full p-20">
                        <div className="my-6">
                            <h1 className="font-bold text-3xl">Welcome Back</h1>
                            <p className="my-2">
                                We are happy to see you again. Let's get to work
                                and improve the e-commerce experience for you
                            </p>
                        </div>
                        <form className="w-full" onSubmit={handleSubmit}>
                            <div className="mb-4 w-full">
                                <label htmlFor="email" className="block">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-4/5 p-2 my-2 rounded-md border border-gray-400"
                                />
                            </div>
                            <div className="flex gap-4 mb-4">
                                <div className="flex-1">
                                    <label htmlFor="password" className="block">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-4/5 p-2 my-2 rounded-md border border-gray-400"
                                    />
                                    <p>
                                        Forgot Password ?
                                        <NavLink
                                            to="/request-password-reset"
                                            className="text-light-red"
                                        >
                                            {" "}
                                            Reset
                                        </NavLink>
                                    </p>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="bg-dark-red p-4 text-white w-1/3 rounded-xl"
                            >
                                Submit
                            </button>
                            {/* <p className="my-2">
                Don't have an account?{" "}
                <NavLink to="/signup" className="text-light-red">
                  {" "}
                  Sign Up
                </NavLink>
              </p> */}
                        </form>
                    </div>
                </>
            )}
            <ToastContainer />
        </div>
    );
}
