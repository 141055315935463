import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../webpages/auth/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
import {AiOutlineLogout} from "react-icons/ai";

export default function SignOutButton() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    axios.defaults.withCredentials = true;
    const fetchCSRFToken = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_SANCTUM_CSRF_URL
        );
        const csrfToken = response.data.token;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
        console.log("CSRF token fetched successfully:", csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCSRFToken();
  }, []);

  const handleLogout = async () => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      toast.error("You are not logged in.");
      return;
    }
  
    try {
      await axios.post(`${process.env.REACT_APP_LARAVEL_APP_API_URL}/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        withCredentials: true, // Ensure cookies are sent with the request
      });
      logout(); // Clear authentication state
      localStorage.removeItem('authToken'); // Remove the token from local storage
      toast.success("Logged out successfully!");
      navigate("/login");
    } catch (error) {
      console.error("Logout failed: ", error);
      toast.error("Logout failed. Please try again.");
    }
  };
  

  return (
    <button onClick={handleLogout} className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center w-full">
       <AiOutlineLogout className="inline h-4 w-4 mr-2" />
      Sign Out
    </button>
  );
}
