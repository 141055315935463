import React from "react";

export default function HeroProductCard({
  title,
  description,
  image,
  productId,
  inverse // This new prop determines the color scheme
}) {
  // Determine the background and text color based on the `inverse` prop
  const bgClass = inverse ? 'bg-[#C0A080]' : 'bg-[#FBF2F1]';
  
  return (
    <div>
      <div className={`rounded-3xl w-56 h-72 ${bgClass} grid place-items-center p-6`}>
        <img src={image} alt={description} className="h-36 w-auto -mt-7" />
        <div className="-my-2">
          <h1 className={`mb-2 font-bold`}>{productId}</h1>
          <h1 className={`font-bold text-sm`}>{title}</h1>
          <p className={`text-xs my-2 line-clamp-3 `}>{description}</p>
        </div>
      </div>
    </div>
  );
}
