import React from "react";
import HeroSection from "../sections/contactus/HeroSection";
import TopNavigaionBar from "../components/TopNavigationBar";
import { Helmet } from 'react-helmet-async';
import ContactForm from "../sections/contactus/ContactForm";
import Footer from "../components/Footer";
import Location from "../sections/contactus/Location";
import FloatingButtons from "../components/FloatingButtons";

export default function ContactUs() {
  return (
    <div>
      <Helmet>
        <title>Newline - Contact Us</title>
        <meta
          name="description"
          content="Reach out to Newline Limited for custom office and home furniture solutions. Contact us for queries, feedback, or to start your project with our expert team in Kenya."
        />
        <meta
          name="keywords"
          content="Newline contact, furniture inquiry, custom furniture Kenya, office furniture contact, home decor solutions, kitchen wardrobes inquiry, commercial interiors Kenya, school furniture Kenya, hospitality furniture contact"
        />
        <link
          rel="canonical"
          href="https://www.newlinelimited.com/contact-us"
        />
        <meta
          property="og:title"
          content="Contact Newline Limited - Your Partner in Furniture Solutions"
        />
        <meta
          property="og:description"
          content="Get in touch with Newline Limited for personalized support and expert guidance on office and home furniture projects. Contact us today."
        />
        <meta
          property="og:url"
          content="https://www.newlinelimited.com/contact-us"
        />
        <meta property="og:type" content="website" />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigaionBar />
      <HeroSection />
      <ContactForm />
      <Location />
      <FloatingButtons/>
      <Footer backgroundColor="bg-gray-100" />
    </div>
  );
}
