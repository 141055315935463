import React from "react";
import ChooseUs from "../../images/about/why-choose-us-img.jpg";
import Shipping from "../../images/about/truck.svg";
import Shop from "../../images/about/bag.svg";
import Support from "../../images/about/support.svg";
import Return from "../../images/about/return.svg";

export default function WhyChooseUs() {
  return (
    <div className="px-16 sm:px-4">
      <div className="grid grid-cols-2 sm:grid-cols-1 place-items-center text-sm">
        <div>
          <div>
            <h1 className="text-2xl">Why Choose Us</h1>
            <p className="my-6">
              Choosing us means investing in unparalleled quality and bespoke
              craftsmanship, where every detail is meticulously curated to
              exceed expectations. Our dedication to sustainability, innovative
              design, and personalized service sets us apart, ensuring a
              distinctive and enriching experience for every client.
            </p>
          </div>
          <div className="grid grid-cols-2">
            <div>
              <img src={Shipping} alt={Shipping} className="my-6" />
              <h1 className="my-3 font-semibold">Fast & Free Shipping</h1>
              <p>
                Enjoy the convenience of fast and free shipping, ensuring your
                selections arrive promptly and without additional cost.
              </p>
            </div>
            <div>
              <img src={Shop} alt={Shop} className="my-6" />
              <h1 className="my-3 font-semibold">Easy to Shop</h1>
              <p>
                Our streamlined online platform makes shopping effortless,
                offering an intuitive browsing experience to find your perfect
                pieces with ease.
              </p>
            </div>
            <div>
              <img src={Support} alt={Support} className="my-6" />
              <h1 className="my-3 font-semibold">24/7 Support</h1>
              <p>
                Receive round-the-clock support from our dedicated team, ready
                to assist you with any inquiries or concerns, anytime, anywhere.
              </p>
            </div>
            <div>
              <img src={Return} alt={Return} className="my-6" />
              <h1 className="my-3 font-semibold">Hassle Free Returns</h1>
              <p>
                Experience peace of mind with our hassle-free returns policy,
                ensuring a straightforward process for exchanges or refunds on
                all purchases.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src={ChooseUs} alt={ChooseUs} className="h-[80vh] sm:my-6 rounded-3xl" />
        </div>
      </div>
    </div>
  );
}
