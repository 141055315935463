import React from "react";
import Image1 from "../../../images/kitchen/1.webp";
import Image2 from "../../../images/kitchen/2.webp";
import Image3 from "../../../images/kitchen/3.webp";

export default function Content() {
    const contentData = [
        {
            id: 1, // Make sure each id is unique
            title: "Streamlined Kitchen Craftsmanship",
            description:
                "Discover Newline Limited's elegant, space-saving modular kitchens, where chic wooden finishes meet modern functionality for the ultimate cooking oasis. Our designs blend spacious countertops, sleek appliances, and easy-to-maintain surfaces for a seamless culinary experience. Elevate your home with our custom, sophisticated kitchen solutions that cater to both passionate cooks and design connoisseurs.",
            imageSrc: Image1,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Precision-Designed Kitchens",
            description:
                "Discover the sleek sophistication of Newline Limited kitchens, where superior global quality and local craftsmanship converge. This kitchen showcases a seamless blend of style and utility with a modern gas cooktop, oven, and microwave nestled in warm wooden cabinetry, highlighted by a striking backsplash. Embrace minimalist design with our elegant, high-quality kitchen solutions. Visit Newline for a tailored experience that transforms your culinary space.",
            imageSrc: Image2,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Newline's Contemporary Kitchen Craft",
            description:
                "Experience Newline Limited's blend of simplicity and sophistication in this modern kitchen. Bathed in natural light, the space features streamlined wooden cabinetry and top-of-the-line appliances set against durable granite counters. Designed for both style and function, our kitchens serve as the foundation of your home's culinary adventures. Discover Newline's dedication to quality and design, where every kitchen detail enhances your home and lifestyle.",
            imageSrc: Image3,
        },
    ];

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            {contentData.map((item, index) => (
                <div
                    key={item.id}
                    className={` ${index % 2 === 0 ? "" : "md:grid-rows-2"}`}
                >
                    {index % 2 === 0 ? (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                            <img src={item.imageSrc} alt="Kitchen" className="sm:my-6"/>
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <img src={item.imageSrc} alt="Kitchen" className="sm:my-6"/>
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
