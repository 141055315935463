import React from "react";
import Sofa from "../images/footer/siena_1seater.webp";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { AiOutlineSend } from "react-icons/ai";

export default function Footer({ backgroundColor }) {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <div className={`grid ${backgroundColor}  p-16 sm:px-4 mt-40`}>
      <div className="flex justify-between">
        <div className="flex justify-between sm:flex-col-reverse">
          <div className="grid w-full">
            <div>
              <div className="flex gap-3 items-center ">
                <MdOutlineMarkEmailUnread size={32} />
                <h1>Subscribe to Newsletter</h1>
              </div>
              <form action="" className="my-4">
                <div className="grid grid-cols-3 sm:grid sm:grid-cols-2 gap-5">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="border border-dark-red p-4 rounded-lg bg-transparent"
                    placeholder="Enter your name"
                  />
                  <input
                    type="email"
                    name=""
                    id=""
                    className="border border-dark-red p-4 rounded-lg bg-transparent"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="w-16 h-16 rounded-md bg-light-red flex justify-center items-center"
                  >
                    <AiOutlineSend color="white" size={32} />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div>
            <img
              src={Sofa}
              alt=""
              className="-mt-48 w-3/5 ml-52 sm:ml-0 sm:w-full"
            />
          </div>
        </div>
      </div>
      <h1 className="font-bold text-3xl mb-6">Newline.</h1>
      <div className="grid grid-cols-11 gap-4 justify-items-center sm:justify-items-start">
        <div className="col-span-3 sm:col-span-11">
          <p className="text-justify text-sm">
            Newline Limited specializes in creating timeless, elegant furniture
            with a blend of global quality and local craftsmanship. Offering
            customization, high-quality materials for office spaces, and home
            interior design guidance, Newline elevates environments with its
            sophisticated collection.
          </p>
          <div className="flex gap-4 my-8">
            <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
              <NavLink
                to="https://www.facebook.com/newlineke?mibextid=ZbWKwL"
                target="_blank"
              >
                <FaFacebookF color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75">
              <NavLink
                to="https://x.com/ltd_newline?t=zuHcIDKUo8LO44i_QIUHvg&s=09"
                target="_blank"
              >
                <FaTwitter color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink
                to="https://www.instagram.com/newline_ltd?igsh=MTdreTgzcGRhM2RsbA=="
                target="_blank"
              >
                <FaInstagram color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink
                to="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
                target="_blank"
              >
                <FaLinkedinIn color="white" />
              </NavLink>
            </div>
            <div className="rounded-full h-10 w-10 bg-light-red flex justify-center items-center p-2 hover:bg-dark-red hover:shadow-lg hover:shadow-gray-300 ease-in-out duration-75 ">
              <NavLink
                to="https://www.tiktok.com/@newlinelimited?_t=8k2LuSsMpV8&_r=1"
                target="_blank"
              >
                <FaTiktok color="white" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-5">
          <p className="mb-2 font-bold">Office Furniture</p>
          <ul className="space-y-2 text-sm">
            <li>Office Chairs</li>
            <li>Office Desks</li>
            <li>Conference Table</li>
            <li>Cabinets</li>
          </ul>
        </div>
        <div className="col-span-2 sm:col-span-5">
          <p className="mb-2 font-bold">Home Furniture</p>
          <ul className="space-y-2 text-sm">
            <li>Living Room</li>
            <li>Dining Room</li>
            <li>Bedrooms</li>
          </ul>
        </div>
        <div className="col-span-2 sm:col-span-5">
          <p className="mb-2 font-bold">Kitchens & Wardrobes</p>
          <ul className="space-y-2 text-sm">
            <li>Kitchens</li>
            <li>Wardrobes</li>
          </ul>
        </div>
      </div>
      {/* Updated section with dynamic year */}
      <div className="text-center my-8 text-sm">
        <p>
          Copyright ©{currentYear}. All Rights Reserved. — Designed & Built by
          Stephen Muchara
        </p>
        <p>
          <a href="/terms-and-conditions" className="hover:underline">
            Terms & Conditions
          </a>{" "}
          |
          <a href="/privacy-policy" className="hover:underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}
