import React, { useState } from "react";
import { motion } from "framer-motion";
import { AiFillPlusCircle } from "react-icons/ai";

const ProductItem = ({ title, mainImageUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const imageVariants = {
    initial: { y: 25 },
    hover: { y: -5 },
  };

  const divVariants = {
    initial: { height: 0, backgroundColor: "rgba(0,0,0,0)" },
    hover: { height: "45vh", backgroundColor: "rgba(64,224,208,0.3)" },
  };

  return (
    <div className="relative">
      <motion.div
        className="relative grid h-[65vh] border border-gray-200 sm:h-[60vh] w-64 p-4 rounded-lg cursor-pointer overflow-hidden"
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        whileHover={{ scale: 1.02 }}
      >
        <div className="h-[40vh] border border-transparent flex items-center justify-center">
          <motion.img
            src={mainImageUrl}
            alt={title}
            className="mx-auto"
            variants={imageVariants}
            initial="initial"
            animate={isHovered ? "hover" : "initial"}
            transition={{ duration: 0.3, ease: "easeOut" }}
          />
        </div>
        <div className="h-[25vh]">
          <h3 className="text-lg text-center font-semibold mt-4">{title}</h3>
        </div>
        <motion.div
          className="absolute bottom-0 left-0 right-0 rounded-lg -z-10"
          variants={divVariants}
          initial="initial"
          animate={isHovered ? "hover" : "initial"}
          transition={{ duration: 0.3, ease: "easeOut" }}
        ></motion.div>
      </motion.div>
      <motion.span
        className="absolute -bottom-7 left-0 right-0 flex justify-center items-center transform -translate-x-1/2 translate-y-1/2 z-10"
        variants={iconVariants}
        initial="hidden"
        animate={isHovered ? "visible" : "hidden"}
        transition={{ duration: 0.3, ease: "easeOut" }}
      >
        <AiFillPlusCircle size={40} color="#00c4b1" />
      </motion.span>
    </div>
  );
};

export default ProductItem;
