import React from "react";
import Image1 from "../../../images/wardrobes/10.webp";
import Image2 from "../../../images/wardrobes/12.webp";
import Image3 from "../../../images/wardrobes/13.webp";

export default function Content() {
    const contentData = [
        {
            id: 1, // Make sure each id is unique
            title: "Flexible Storage Solutions",
            description:
                "Experience the ease and comfort of Newline's ergonomically designed wardrobes, which ensure that every item is within easy reach. The thoughtful placement of hanging rods, drawers, and shelves considers user comfort and the practical aspects of daily use, making getting ready a seamless and enjoyable process.",
            imageSrc: Image1,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Ergonomic and User-Friendly Layouts",
            description:
                "Experience the ease and comfort of Newline's ergonomically designed wardrobes, which ensure that every item is within easy reach. The thoughtful placement of hanging rods, drawers, and shelves considers user comfort and the practical aspects of daily use, making getting ready a seamless and enjoyable process.",
            imageSrc: Image2,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Customizable Aesthetic Options",
            description:
                "Step into the realm of personalized style with Newline's customizable wardrobe designs. Choose from a wide range of materials, finishes, and color palettes to mirror your unique taste and complement your home's décor. Our wardrobes are not just storage solutions but statement pieces that reflect your personal style.",
            imageSrc: Image3,
        },
    ];

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            {contentData.map((item, index) => (
                <div
                    key={item.id}
                    className={` ${index % 2 === 0 ? "" : "md:grid-rows-2"}`}
                >
                    {index % 2 === 0 ? (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
