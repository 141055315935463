import React from "react";
import Footer from "../../../components/Footer";
import productData from "../../../data/office/chairs/MidBackChairs.json";
import ProductItem from "../../../components/ProductItem";
import HeroSection from "./HeroSection";
import TopNavigationBar from "../../../components/TopNavigationBar";
import Image from "../../../images/homepage/eco_mid_back.webp";
import { Helmet } from "react-helmet-async";
import FloatingButtons from "../../../components/FloatingButtons";

export default function MidBackChairs() {
  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>
          Mid Back Office Chairs | Ergonomic Support & Style | Newline Limited
        </title>
        <meta
          name="description"
          content="Find the perfect mid-back office chair with Newline Limited, combining ergonomic support with flexibility for dynamic workspaces. Explore our range designed for comfort and style."
        />
        <meta
          name="keywords"
          content="mid back office chairs, ergonomic chairs, office furniture Kenya, Newline Limited, comfortable office chairs, flexible seating solutions, office style chairs"
        />
        <link
          rel="canonical"
          href="https://www.newline.co.ke/office-furniture/chairs/mid-back-chairs"
        />
        <meta
          property="og:title"
          content="Mid Back Office Chairs | Ergonomic Support & Style | Newline Limited"
        />
        <meta
          property="og:description"
          content="Explore ergonomic mid-back office chairs at Newline Limited, designed for comfort and flexibility in dynamic office environments."
        />
        <meta
          property="og:url"
          content="https://www.newline.co.ke/office-furniture/chairs/mid-back-chairs"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="URL_TO_FEATURED_MID_BACK_CHAIR_IMAGE"
        />
        <meta
          property="newline:facebook"
          content="https://www.facebook.com/newlineke"
        />
        <meta property="newline:twitter" content="https://x.com/ltd_newline" />
        <meta
          property="newline:instagram"
          content="https://www.instagram.com/newline_ltd"
        />
        <meta
          property="newline:linkedin"
          content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
        />
        <meta
          property="newline:tiktok"
          content="https://www.tiktok.com/@newlinelimited"
        />
      </Helmet>

      <TopNavigationBar />
      <HeroSection
        title="Mid Back Office Chairs"
        description="Looking for mid-back office chairs that offer both comfort and flexibility? Our team specializes in ergonomic designs perfect for dynamic workspaces, ensuring ease of movement and support for extended sitting. We're here to help you find the right chair that matches your office style and meets your specific needs."
        imageURL={Image}
        imageHeight="w-3/5"
      />
      <div className="grid grid-cols-4 sm:grid-cols-1 gap-x-8 mx-16 my-0 sm:m-4 sm:gap-x-0 sm:place-items-center">
        {productData.products.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <ProductItem
              title={product.title}
              mainImageUrl={product.details.mainImageUrl} // Ensure this matches your component's props
              tags={product.tags}
            />
          </div>
        ))}
      </div>
      <FloatingButtons/>
      <Footer backgroundColor="bg-white" />
    </div>
  );
}
