import React from "react";
import Auditorium from "../../images/homepage/auditorium.webp";
import Office from "../../images/homepage/office.webp";
import Mockup from "../../images/homepage/mockup.png";

export default function ExtraInfo() {
  return (
    <div className="grid grid-cols-12 sm:grid-cols-1 my-10 h-[100vh] sm:h-[200vh] bg-gray-200">
      <div className="col-span-4">
        <img
          src={Auditorium}
          alt=""
          className="w-full h-full object-cover object-center sm:hidden"
        />
      </div>
      <div className="col-span-3">
        <div className="h-[50vh]">
          <img
            src={Mockup}
            alt=""
            className="w-full h-full object-cover object-center"
          />
        </div>
        <div className="h-[50vh] sm:h-[40vh] p-6">
          <h1 className="my-6 font-bold text-2xl">Cozy Looks</h1>
          <p>
            Explore the essence of coziness with our bespoke furniture designs.
            Each piece is a testament to warm textures and soothing aesthetics,
            perfect for creating your haven of relaxation.
          </p>
        </div>
      </div>
      <div className="col-span-5 grid">
        <div className="h-[50vh] sm:h-[40vh] p-10 sm:p-6">
          <h1 className="my-6 font-bold text-2xl">Comfortable Feel</h1>
          <p>
            Dive into a world where comfort meets class. Our furniture
            selections are crafted to enrich your living space with an inviting
            ambiance and a touch of luxury.
          </p>
        </div>
        <div className="h-[50vh]">
          <img
            src={Office}
            alt=""
            className="w-full h-full object-cover object-center"
          />
        </div>{" "}
      </div>
    </div>
  );
}
