import React from "react";
import Image1 from "../../../images/commercial/02.webp";
import Image2 from "../../../images/commercial/06.webp";
import Image3 from "../../../images/commercial/05.webp";

export default function Content() {
    const contentData = [
        {
            id: 1, // Make sure each id is unique
            title: "Flexible Office Layouts",
            description:
                "Discover the revolution in workplace environments with Newline Interiors, where adaptable furniture and intelligent technology integration foster a fluid space for collaborative and focused work experiences. Our designs highlight spacious layouts, ergonomic workstations, and advanced technological infrastructures to support a variety of work styles and business activities.",
            imageSrc: Image1,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Engaging Break and Common Areas",
            description:
                "Delve into the core of corporate culture with Newline's innovatively designed common areas. These spaces promote teamwork, relaxation, and innovation among employees, featuring versatile seating solutions, stimulating colors, and multi-use zones prepared for everything from casual meetings to relaxation and informal brainstorming sessions.",
            imageSrc: Image2,
        },
        {
            id: 2, // Corrected ID to be unique
            title: "Technology-Integrated Spaces",
            description:
                "Embrace the future with commercial spaces designed to seamlessly incorporate the latest in digital solutions. Whether through advanced presentation systems in conference rooms, digital product catalogs in retail spaces, or smart lighting and climate control for optimal energy efficiency, Newline Interiors ensures that every aspect of your space is equipped to meet the demands of tomorrow's business landscape.",
            imageSrc: Image3,
        },
    ];

    return (
        <div className="sm:px-4 p-12 sm:my-4">
            {contentData.map((item, index) => (
                <div
                    key={item.id}
                    className={` ${index % 2 === 0 ? "" : "md:grid-rows-2"}`}
                >
                    {index % 2 === 0 ? (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 place-items-center my-20 sm:my-4">
                            <img
                                src={item.imageSrc}
                                alt="Kitchen"
                                className="sm:my-6"
                            />
                            <div>
                                <h1 className="font-bold text-xl w-4/5 my-6">
                                    {item.title}
                                </h1>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
