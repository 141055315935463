import React from "react";
import Footer from "../../../components/Footer";
import TopNavigationBar from "../../../components/TopNavigationBar";
import { Helmet } from "react-helmet-async";
import HeroSection from "./HeroSection";
import KitchenGallery from "./KitchenGallery";
import Content from "./Content";
import FloatingButtons from "../../../components/FloatingButtons";

export default function Kitchens() {
    return (
        <div className="relative">
            <Helmet>
                <title>
                    Newline Limited - Premium Office and Home Furniture in Kenya
                </title>
                <meta
                    name="description"
                    content="Newline Limited specializes in crafting timeless elegance in office furniture, home decor, and kitchen & wardrobe solutions. Discover our high-quality, locally crafted furniture designed for comfort and sophistication."
                />
                <meta
                    name="keywords"
                    content="office furniture Kenya, custom kitchen cabinets, home decor, school furniture, commercial interiors, Nairobi furniture, high-quality furniture, interior design services, luxury furniture Kenya"
                />
                <link rel="canonical" href="https://www.newlinelimited.com/" />
                <meta
                    property="og:title"
                    content="Newline Limited - Premium Office and Home Furniture in Kenya"
                />
                <meta
                    property="og:description"
                    content="Explore Newline Limited for global quality craftsmanship in office furniture, kitchens, wardrobes, and home interiors. Tailored to your style and comfort."
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://www.newlinelimited.com/"
                />
                <meta property="og:image" content="URL_TO_IMAGE" />
                <meta
                    property="newline:facebook"
                    content="https://www.facebook.com/newlineke"
                />
                <meta
                    property="newline:twitter"
                    content="https://x.com/ltd_newline"
                />
                <meta
                    property="newline:instagram"
                    content="https://www.instagram.com/newline_ltd"
                />
                <meta
                    property="newline:linkedin"
                    content="https://www.linkedin.com/company/newline-limited-nairobi-kenya/"
                />
                <meta
                    property="newline:tiktok"
                    content="https://www.tiktok.com/@newlinelimited"
                />
            </Helmet>
            <TopNavigationBar />
            <HeroSection />
            <Content/>
            <KitchenGallery />
            <FloatingButtons/>
            <Footer backgroundColor="bg-gray-100" />
        </div>
    );
}
