import React from "react";
import Bed from "../../images/homepage/bed.png";
import Bedroom from "../../images/homepage/bedroom.png";
import ChairSet from "../../images/homepage/chair-set.png";
import CoffeeSet from "../../images/homepage/coffee-set-up.png";
import Dining from "../../images/homepage/dining.png";
import Kitchen from "../../images/homepage/kitchen.png";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <div className="sm:px-4 py-16 sm:my-4">
      <div className="grid grid-cols-3 sm:grid-cols-1 items-center">
        <div className="grid gap-16 place-items-center">
          <img
            src={CoffeeSet}
            alt="Furniture"
            className="w-44 h-44 object-cover md:h-auto sm:hidden"
          />
          <img
            src={Bedroom}
            alt="Furniture"
            className="w-72 object-cover h-60 md:h-auto sm:hidden"
          />
        </div>

        {/* Right side content */}
        <div className="flex flex-col justify-center items-center">
          <img
            src={Bed}
            alt="Furniture"
            className="w-80 object-cover h-64 md:h-auto mb-16 sm:hidden"
          />
          <div className="text-center justify-center items-center sm:my-8">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">
              Crafting Timeless Elegance with Distinctive Authority
            </h1>
            <p className="mb-6">
              Welcome to our world of exquisite craftsmanship, where every piece
              of furniture is meticulously designed to embody timeless elegance
              and command a distinctive presence in your space. Explore our
              premium collection and elevate your surroundings with unparalleled
              authenticity and sophistication.
            </p>
            <NavLink to="/about-us" className="self-start py-2 px-4 border border-light-red text-light-red rounded-lg hover:bg-dark-red hover:text-white transition-colors">
              Read About Us
            </NavLink>
          </div>
        </div>
        <div className="grid">
          <img
            src={ChairSet}
            alt="Furniture"
            className="w-24 h-24 object-cover my-16 sm:hidden"
          />
          <div className="flex justify-end">
            <img
              src={Dining}
              alt="Furniture"
              className="w-52 h-48 object-cover my-16 md:h-auto sm:hidden"
            />
          </div>
          <img
            src={Kitchen}
            alt="Furniture"
            className="w-80 h-60 mx-8 object-cover md:h-auto sm:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
