import React from "react";

const ConfirmationDialog = ({ isOpen, onCancel, onConfirm }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center pt-10 z-30 bg-black bg-opacity-75">
            <div className="bg-white p-8 rounded shadow-lg z-50">
                <p className="mb-4">
                    Are you sure you want to delete this product?
                </p>
                <div className="flex justify-end">
                    <button
                        onClick={onCancel}
                        className="mr-4 bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;
