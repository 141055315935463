import React, { useState, useEffect } from "react";
import HeroProductCard from "../../components/HeroProductCard";
import productData from "../../data/ProductData.json"; // Adjust the path as necessary
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ShuffleGallery() {
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [cursorStyle, setCursorStyle] = useState("cursor-pointer"); // Default cursor style

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const shuffledData = shuffleArray(
      productData.categories.flatMap((category) => category.products)
    );
    const selectedProducts = shuffledData.slice(0, 9);

    setDisplayedProducts(selectedProducts);

    const interval = setInterval(() => {
      const shuffledDataAgain = shuffleArray(
        productData.categories.flatMap((category) => category.products)
      );
      const selectedProductsAgain = shuffledDataAgain.slice(0, 8);
      setDisplayedProducts(selectedProductsAgain);
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 728,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Mouse down handler
  const handleMouseDown = () => {
    setCursorStyle("cursor-grabbing");
  };

  // Mouse up handler
  const handleMouseUp = () => {
    setCursorStyle("cursor-pointer");
  };

  return (
    <div
      className={`w-3/5 sm:w-3/4 ${cursorStyle}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Reset cursor when leaving the component area
    >
      <Slider {...sliderSettings}>
        {displayedProducts.map((product, index) => (
          <div key={product.id} className="p-4 sm:p-2">
            <HeroProductCard
              title={product.title}
              description={product.description}
              image={product.details.mainImageUrl}
              inverse={index % 2 !== 0}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
